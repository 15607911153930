import ButtonPrimary from 'component/Common/ButtonPrimary';
import CommonModal from 'component/Common/CommonModal';
import React from 'react'

const NoAvailabilityModal = (props) => {
    const { close, phoneNumber } = props
    return (
        <CommonModal>
            <p>We have the availability of the services you selected but they cannot be done in the same appointment. Please call us to book and we can provide guidance on how best to schedule your treatments.</p>
            <p className='w-full text-left'>Call us <a href={`tel:${phoneNumber}`} className='no-underline'>{phoneNumber}</a> </p>
            <div className='flex justify-center'>
                <ButtonPrimary text='Ok' onClick={close}/>
            </div>
        </CommonModal>
    )
}

export default NoAvailabilityModal;