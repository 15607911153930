import React, {useEffect} from 'react';
import './css/App.css';
import Header from './component/Header';
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import useSessionHandShake from './hooks/useSessionHandShake';
import HomePage from './component/pages/HomePage';
import HashRedirectPage from 'component/pages/HashRedirectPage';
import CartPage from './component/pages/CartPage';
import RedirectPage from './component/pages/RedirectPage';
import StaffPage from './component/pages/StaffPage';
import MembershipsPage from './component/pages/MembershipsPage';
import DataStore from 'stores/DataStore';
import Loader from './component/Loader';
import {observer} from 'mobx-react-lite';
import PromotionsPage from './component/pages/PromotionsPage';
import GiftCardsPage from './component/pages/GiftCardsPage';
import GiftCardPurchasePage from './component/pages/GiftCardPurchasePage';
import AppointmentBooking from './component/Booking/AppointmentBooking';
import CartPaymentPage from 'component/pages/CartPaymentPage';
import StripeCompletedPayment from 'component/pages/StripeCompletedPayment';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import CompletedPaymentPage from 'component/pages/CompletedPaymentPage';
import MembershipBenefitPage from 'component/pages/MembershipBenefitPage';
import CheckInvoiceStatus from 'component/pages/CheckInvoiceStatus';
import Authorization from 'component/pages/Authorization';
import ClinicsStore from 'stores/ClinicsStore';
import { initializeGA } from 'Utils/GoogleAnalytics';
import MaintenancePage from 'component/pages/MaintenancePage';
import { MAINTENANCE_MODE } from 'Utils/constants';
import LandingPageSuccess from "component/pages/LandingPageSuccess";
import LandingPageFailed from "component/pages/LandingPageFailed";
import { Footer } from 'component/Footer';

const App = () => {
  const { isGettingData, isServicesDisabled, accountConfig } = DataStore
  const { selectedClinic } = ClinicsStore;
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const MAINTENANCE_PATH = '/online-booking/maintenance'
  const MAINTENANCE_TEST_ACCOUNT = ['lovelylines', 'beautifullines']
  const ACCOUNT_NAME = window.location.hostname.split('.')[0]
  const MAINTENANCE_ENABLED = MAINTENANCE_MODE && !MAINTENANCE_TEST_ACCOUNT.includes(ACCOUNT_NAME)

  useSessionHandShake()

  useEffect(() => {
    if (isServicesDisabled && pathname === '/online-booking') {
      navigate('/online-booking/book-appointment')
    }
  }, [accountConfig, isServicesDisabled, selectedClinic])

  useEffect(() => {
    if (accountConfig?.analytics_tracking_id) {
      initializeGA(accountConfig.analytics_tracking_id);
    }
  }, [accountConfig]);

  useEffect(() => {
    document.title = selectedClinic?.clinic_name || 'Online Booking'
  }, [selectedClinic?.clinic_name])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })

    if (MAINTENANCE_MODE && pathname !== MAINTENANCE_PATH && !MAINTENANCE_TEST_ACCOUNT.includes(ACCOUNT_NAME)) {
      navigate(MAINTENANCE_PATH)
    }
  }, [pathname])

  return (
    <>
      {isGettingData && <Loader />}
      {!MAINTENANCE_ENABLED && <Header />}
      <Routes>
        <Route path="*" element={<Navigate to="/online-booking" />} />
        <Route path="/online-booking" element={<HomePage />} />
        <Route
          path="/online-booking/payment-transaction-success"
          element={<LandingPageSuccess />}
        />
        <Route
          path="/online-booking/payment-transaction-failed"
          element={<LandingPageFailed />}
        />
        <Route path="/online-booking/hash/:hash" element={<HashRedirectPage />} />
        <Route path="/online-booking/h/:hash" element={<HashRedirectPage />} />
        <Route path="/online-booking/cart" element={<CartPage />} />
        <Route path="/online-booking/cart/payment" element={<CartPaymentPage />} />
        <Route path="/online-booking/cart/stripe-completed-payment" element={<StripeCompletedPayment />} />
        <Route path="/online-booking/cart/check-invoice-status" element={<CheckInvoiceStatus />} />
        <Route path="/online-booking/redirect/:where" element={<RedirectPage />} />
        <Route path="/online-booking/staff" element={
          <Authorization condition={'isStaffActive'}>
            <StaffPage />
          </Authorization>
        } />
        <Route path="/online-booking/memberships" element={
          <Authorization condition={'isMembershipActive'}>
            <MembershipsPage />
          </Authorization>
        } />
        <Route path="/online-booking/promotions" element={
          <Authorization condition={'isPromotionActive'}>
            <PromotionsPage />
          </Authorization>
        } />
        <Route path="/online-booking/giftcards" element={
          <Authorization condition={'isGiftCardActive'}>
            <GiftCardsPage />
          </Authorization>
        } />
        <Route path="/online-booking/giftcards/card/:id" element={<GiftCardPurchasePage />} />
        <Route path="/online-booking/memberships/membership-details/:id" element={<MembershipBenefitPage />} />
        <Route path="/online-booking/book-appointment" element={<AppointmentBooking />} />
        <Route path="/online-booking/cart/completed-payment" element={<CompletedPaymentPage />} />
        {MAINTENANCE_MODE && <Route path={MAINTENANCE_PATH} element={<MaintenancePage />} />}
      </Routes>
      <ToastContainer position="bottom-left" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnVisibilityChange draggable pauseOnHover />
      <Footer />
    </>
  );
}

export default observer(App);
