import React from 'react'
import TrashIcon from '../../img/trashIcon.svg'
import { removeItemFromCart } from 'services/HttpServices';
import DataStore from 'stores/DataStore';
import '../../css/Themes.scss'
import { formatCurrencyAmount } from 'Utils/globalActions';

const CartItem = (props) => {
  const { cartItem } = props;
  const { accountInfo, accountConfig } = DataStore

  const handleRemoveFromCart = () => {
    removeItemFromCart(cartItem.id)
  }

  const currencyCode = accountInfo.currency_code || 'USD'

  return (
    <div className='p-4 flex justify-between font-semibold border border-[#E2E8F0] rounded-lg'>
      <div className='flex flex-col gap-3'>
        <h4 className='text-[#1E293B] text-capitalize text-base'>{cartItem.title}{cartItem?.quantity > 1 ? ` - ${cartItem.quantity} Promotions` : ''}{cartItem.frequency ? ` - ${cartItem.frequency} Frequency` : ''}</h4>
        <h4 className={`${accountConfig.theme}-theme primary-color font-bold text-2xl`}>{formatCurrencyAmount(currencyCode, cartItem.price)}</h4>
      </div>
      <button
        className='flex h-5 gap-2 cursor-pointer'
        onClick={handleRemoveFromCart}>
        <img src={TrashIcon} alt='trash_icon'/>
        <span className='text-[#475569] text-sm'>Remove</span>
      </button>
    </div>
  )
}

export default CartItem;