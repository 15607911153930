import React, { useEffect } from 'react'
import RightContainer from '../RightContainer';
import Steps from './Steps';
import StepOne from './StepsContent/StepOne';
import ClinicsStore from 'stores/ClinicsStore';
import StepTwo from './StepsContent/StepTwo';
import StepThree from './StepsContent/StepThree';
import StepFour from './StepsContent/StepFour';
import useGetClinicAppointment from 'hooks/useGetClinicAppointment';
import BookingStore from 'stores/BookingStore';
import { observer } from 'mobx-react-lite';
import BookingPayment from './StepsContent/BookingPayment';
import SuccessPayment from './StepsContent/SuccessPayment';
import DataStore from 'stores/DataStore';
import '../../css/Themes.scss'
import { shortenClinicName, subdomain } from 'Utils/globalActions';
import ServicesStore from 'stores/ServicesStore';
import StaffStore from 'stores/StaffStore';
import { formatPhoneNumber } from 'react-phone-number-input';

const AppointmentBooking = () => {

  const { selectedClinic } = ClinicsStore;
  const { step, selectedServices, selectedProvider, selectedDateAndHour, contactInfo } = BookingStore
  const { accountConfig } = DataStore;
  const { preselectedService } = ServicesStore;
  const { preselectedProvider } = StaffStore;

  const hash = sessionStorage.getItem('booking-hash')

  const renderSelectedServices = () => {
    if(selectedServices.length > 0) {
      return  selectedServices.map(service => <span className={`${accountConfig.theme}-theme primary-color block text-[16px]`} key={service.id}>{service.name}</span>)
    }
    if(preselectedService) {
      return <span className={`${accountConfig.theme}-theme primary-color block text-[16px]`}>{preselectedService.name}</span>
    }
  }

  const renderSelectedProvider = () => {
    if(preselectedProvider) {
      return <span className={`${accountConfig.theme}-theme primary-color mb-6 block text-[16px]`}>{StaffStore.preselectedProvider.bio_name}</span>
    }
    return <span className={`${accountConfig.theme}-theme primary-color mb-6 block text-[16px]`}>{selectedProvider?.bio_name}</span>
  }

  useGetClinicAppointment({ subdomain, hash })

  useEffect(() => {
    return () => {BookingStore.reset(); ServicesStore.setPreselectedService(null); StaffStore.setPreselectedProvider(null)}
  }, [])

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
              <div className="lg:px-4 py-4 min-h-screen max-h-full" >
                  <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
                      <div className="w-full h-fit bg-white container-shadow p-[8px] rounded-lg hidden lg:p-4 lg:w-[400px] lg:block xl:p-6 ">
                        <h2 className="text-lg mb-3 xl:text-xl pb-4 border-b ">Details</h2>
                        <h5 className='text-[#64748B] text-[16px] font-semibold'>Location</h5>
                        <span className={`${accountConfig.theme}-theme primary-color mb-6 block text-[16px]`}>{shortenClinicName(selectedClinic?.clinic_name)}</span>
                        {step > 1 && <>
                          <h5 className='text-[#64748B] text-[16px] font-semibold'>Your Info</h5>
                          <span className={`${accountConfig.theme}-theme primary-color block text-[16px]`}>{`${contactInfo.firstname} ${contactInfo.lastname}`}</span>
                          <span className={`${accountConfig.theme}-theme primary-color block text-[16px]`}>{contactInfo.email}</span>
                          <span className={`${accountConfig.theme}-theme primary-color mb-6 block text-[16px]`}>{formatPhoneNumber(`+${contactInfo.phone_number}`.replace('++', '+'))}</span>
                          </>}
                        {(selectedServices.length > 0 || preselectedService) && <div className='mb-6'>
                        <h5 className='text-[#64748B] text-[16px] font-semibold'>Services</h5>
                          {renderSelectedServices()}
                        </div>}
                        {(step > 3 || preselectedProvider) && <>
                        <h5 className='text-[#64748B] text-[16px] font-semibold'>Provider</h5>
                        {renderSelectedProvider()}</>}
                        {step > 4 && <>
                          <h5 className='text-[#64748B] text-[16px] font-semibold'>Date & Time</h5>
                          <span className={`${accountConfig.theme}-theme primary-color mb-6 block text-[16px]`}>{selectedDateAndHour}</span> </>}
                      </div>
                      <RightContainer title='Book an Appointment'>
                          {step < 5 && <Steps step={step} />}
                          {step === 1 && <StepOne />}
                          {step === 2 && <StepTwo />}
                          {step === 3 && <StepThree />}
                          {step === 4 && <StepFour />}
                          {step === 5 && <BookingPayment />}
                          {step === 6 && <SuccessPayment />}
                      </RightContainer>
                  </div>
              </div>
          </div>
        </>
  )
}

export default observer(AppointmentBooking);
