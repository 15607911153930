import React from 'react'
import '../css/Themes.scss'
import DataStore from 'stores/DataStore'

const UserIcon = () => {
    const { accountConfig } = DataStore;
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill='none' className={`${accountConfig.theme}-theme stroke-primary`} xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 15.6667C3.44649 13.6021 6.08918 12.3333 9 12.3333C11.9108 12.3333 14.5535 13.6021 16.5 15.6667M12.75 5.25C12.75 7.32107 11.0711 9 9 9C6.92893 9 5.25 7.32107 5.25 5.25C5.25 3.17893 6.92893 1.5 9 1.5C11.0711 1.5 12.75 3.17893 12.75 5.25Z" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default UserIcon;


