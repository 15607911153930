import Loader from "component/Loader";
import useGetInvoiceData from "hooks/useGetInvoiceData";
import { observer } from "mobx-react-lite";

const CheckInvoiceStatus = () => {

    useGetInvoiceData()

  return (
    <Loader />
  );
}

export default observer(CheckInvoiceStatus);