import { useEffect } from 'react'
import API from "Utils/API";
import ClinicsStore from 'stores/ClinicsStore'
import ServicesStore from 'stores/ServicesStore'

const useGetServices = () => {
  const getServices = async () => {
    try {
      const servicesData = await API.get('services')
      ServicesStore.setServicesData(servicesData.data.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (ClinicsStore.selectedClinic) {
      getServices()
    }
  }, [ClinicsStore.selectedClinic])

}

export default useGetServices;