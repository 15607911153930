import React from 'react'
import InputField from '../Common/InputField';

const GiftCardForm = (props) => {
    const { giftCardForm, handleGiftCardForm } = props;
    const inputFields = [
        {
            label:'Recipient Name',
            placeholder: 'Enter Recipient Name',
            name: 'recipient_name',
        },
        {
            label:'Recipient Email',
            placeholder: 'Enter Recipient Email',
            name: 'recipient_email',
        },
        {
            label:'Your Name',
            placeholder: 'Enter Your Name',
            name: 'buyer_name',
        },
        {
            label:'Your Email',
            placeholder: 'Enter Your Email',
            name: 'buyer_email',
        },
    ]
    return (
        <>
            <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 mb-6'>
                {inputFields.map(input => <InputField key={input.name} label={input.label} placeholder={input.placeholder} name={input.name} giftCardForm={giftCardForm} handleGiftCardForm={handleGiftCardForm} />)}
            </div>
            <div className='mb-4'>
                <label htmlFor='personal_message'>Personal Message (optional)</label>
                <textarea name='personal_message' className='w-full h-[100px] border border-[##D0D5DD] rounded-lg p-2 resize-none' placeholder='Have a great birthday! Can’t wait to meet again!' onChange={handleGiftCardForm}></textarea>
            </div>
        </>
    )
}

export default GiftCardForm;