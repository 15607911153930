import { makeAutoObservable } from "mobx";

class CartStore {
  totalItems = 0;
  totalPrice = 0;
  totalTax = 0;
  cartItems = new Map();
  invoiceData = null;
  isGuestCheckout = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTotalItems = (value) => {
    this.totalItems = value;
  };

  setCart = (cart) => {
    this.cartItems = new Map(cart.items.map((item) => [item.id, item]));
    this.totalPrice = cart.totalPrice;
    this.totalTax = cart.totalTax;
    this.totalItems = cart.totalItems;
    this.isGuestCheckout = cart.allowGuestCheckout;
  };

  setInvoiceData = (value) => {
    this.invoiceData = value;
  };
}

export default new CartStore();
