import { useEffect } from "react";

const useManifestUpdater = ({ name, logo_url }) => {
  useEffect(() => {
    const modifiedManifest = {
      ...document.manifest,
      short_name: name,
      name,
      start_url: ".",
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
      icons: [
        {
          src: logo_url,
          sizes: "64x64 32x32 24x24 16x16 192x192 512x512",
        },
      ],
    };

    const modifiedManifestString = JSON.stringify(modifiedManifest);

    const blob = new Blob([modifiedManifestString], {
      type: "application/json",
    });

    const manifestURL = URL.createObjectURL(blob);

    const link = document.querySelector('link[rel="manifest"]');
    if (link) {
      link.href = manifestURL;
    }

    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon && logo_url) {
      favicon.href = logo_url;
    }
  }, [name, logo_url]);
};

export default useManifestUpdater;
