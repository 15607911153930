import { useScrollToTop } from 'hooks/useSrollToTop';
import AppleIcon from 'img/AppleIcon';
import CalendarIcon from 'img/CalendarIcon';
import GoogleIcon from 'img/GoogleIcon';
import YahooIcon from 'img/YahooIcon';
import { observer } from 'mobx-react-lite';
import React from 'react'
import { addToCalendar } from 'services/HttpServices';
import BookingStore from 'stores/BookingStore';
import ClinicsStore from 'stores/ClinicsStore';
import { shortenClinicName, subdomain } from 'Utils/globalActions';

const SuccessPayment = () => {
    useScrollToTop();
    const { contactInfo, selectedDateAndHour, selectedProvider, selectedServices, bookAppointment } = BookingStore
    const { selectedClinic } = ClinicsStore

    const calendarsType = [
        {
            name: 'yahoo',
            icon: <YahooIcon />
        },
        {
            name: 'google',
            icon: <GoogleIcon />
        },
        {
            name: 'apple',
            icon: <AppleIcon />
        },

    ]

    const handleAddToCalendar = async (calendarType) => {
        const formData = {
            appointment_id: bookAppointment?.appointment_id,
            subdomain,
            calendar_type: calendarType
        }
        const windowRedirect = window.open()

        try {
            const response = await addToCalendar(formData)
            windowRedirect.location = response.data.location
            windowRedirect.target = '_blank'
        } catch(e) {

        }
    }

    return (
        <div className='bg-white p-6'>
            <div className='flex flex-column items-center mb-10'>
                <div className='payment-image mb-6'>
                    <svg width="71" height="72" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M35.5009 0.799948C42.4628 0.799948 49.2684 2.86439 55.057 6.73222C60.8456 10.6 65.3573 16.0975 68.0215 22.5295C70.6857 28.9615 71.3828 36.039 70.0246 42.8671C68.6664 49.6953 65.3139 55.9673 60.3911 60.8901C55.4683 65.8129 49.1962 69.1654 42.3681 70.5236C35.54 71.8818 28.4624 71.1847 22.0305 68.5205C15.5985 65.8563 10.101 61.3446 6.23319 55.556C2.36537 49.7674 0.300924 42.9618 0.300924 35.9999C0.287725 31.3737 1.18919 26.7905 2.95348 22.5139C4.71777 18.2373 7.31008 14.3516 10.5813 11.0804C13.8526 7.8091 17.7382 5.21679 22.0149 3.4525C26.2915 1.68821 30.8747 0.786749 35.5009 0.799948Z" fill="#3BB54A"/>
                        <path d="M55 26.3002L30 51.3002L16 37.4002L21.7 31.8002L30 40.0002L49.3 20.7002L55 26.3002Z" fill="#D4E1F4"/>
                    </svg>
                </div>
                <span className='text-[#64748B]'>Thanks for booking your appointment with {shortenClinicName(selectedClinic.clinic_name)}. <br></br>
                        Below are the appointment details for your reference.</span>
            </div>
            <div className='paymentInfo-grid mb-6'>
                <div className='paymentInfo-left'>
                    <div className='mb-6'>
                        <h6 className='text-[#64748B]'>Your Info</h6>
                        <span className='block'>{`${contactInfo.firstname} ${contactInfo.lastname}`}</span>
                        <span className='block'>{contactInfo.email}</span>
                        <span className='block'>{contactInfo.phone_number}</span>
                    </div>

                    <div className='mb-6'>
                        <h6 className='text-[#64748B]'>Location</h6>
                        <span className='mb-6 block'>{shortenClinicName(selectedClinic.clinic_name)}</span>
                    </div>
                    <div className='mb-6'>
                        <h6 className='text-[#64748B]'>Date & Time</h6>
                        <span>{selectedDateAndHour}</span>
                    </div>
                </div>
                <div className='paymentInfo-right'>
                    <div className='mb-6'>
                        <h6 className='text-[#64748B]'>Provider</h6>
                        <span className='block'>{selectedProvider.full_name}</span>
                    </div>
                    <div className='mb-6'>
                        <h6 className='text-[#64748B]'>Services</h6>
                        {selectedServices.map(service => {
                            return <span className='block' key={service.name}>{service.name}</span>
                        })}
                    </div>
                </div>
            </div>
            <div className='flex flex-column items-center'>
                <div className='mt-6 mb-4'> <span className='font-semibold text-[#64748B]'>We look forward to seeing you then!</span></div>
                <CalendarIcon />
                <span className='font-semibold text-[#64748B] mb-2'>Add to Calendar</span>
                <div className='flex gap-2'>
                    {calendarsType.map(calendarType => {
                        return <div className='flex gap-1 w-[85px] justify-center items-center p-1 border-[1px] border-black rounded-md cursor-pointer'
                                    onClick={() => handleAddToCalendar(calendarType.name)} key={calendarType.name}>
                            {calendarType.icon}
                            <span className='capitalize'>{calendarType.name}</span>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default observer(SuccessPayment);