import ButtonPrimary from 'component/Common/ButtonPrimary';
import ButtonSecondary from 'component/Common/ButtonSecondary';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas'
import { toast } from 'react-toastify';
import { uploadImage, validateCoupon } from 'services/HttpServices';
import DataStore from 'stores/DataStore';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const MembershipAgreementModal = (props) => {
    const { agreement, handleAddToCart, setShowAgreementModal, selectedFrequency, membership, setSelectedFrequency } = props;
    const { accountConfig } = DataStore
    const [signature, setSignature] = useState(null)
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true)
    const [agreeMembership, setAgreeMembership] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [couponValidated, setCouponValidated] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        GAEvent(GAEventTypes.MEMBER_INIT);
    }, [])
    
    const resetSignature = () => {
        signature.clear()
        setIsSignatureEmpty(true)
    }

    const agreementHtml = agreement

    const handleAgreement = () => {
        setAgreeMembership(!agreeMembership)
    }
    
    const handleCouponCode = (event) => {
        setCouponCode(event.target.value)
    }

    const handleValidateCoupon = async () => {
        if(!couponCode) {
            return toast.error('Please Enter a Coupon Code!')
        }
        const formData = { 
            coupon_code: couponCode,
            payment_frequency: selectedFrequency
         }
        try{
            const response = await validateCoupon(formData)
            if(response.data.data.is_valid) {
                return setCouponValidated(true)
            }
            return setCouponCode('')
        } catch(error) {

        }
    }

    const handleSubmit = async () => {
        try{
            await uploadImage({ image_data: signature?.toDataURL(), upload_type: 'patient_signatures' })
            await handleAddToCart(couponCode)
            GAEvent(GAEventTypes.MEMBER_ADDED);
        } catch (error) {
            
        }
        setSelectedFrequency(membership.membership_payment_options)
        setShowAgreementModal(false)
        navigate('/online-booking/memberships')
    }

    const prepareAgreementHtml = (html = "") => {
        // Safari browser doesn't support text-wrap: wrap; for now
        if (!CSS.supports("text-wrap", "wrap")) {
          return html.replace(
            /(text-wrap:wrap;|text-wrap:\s+wrap;)+/g,
            "white-space: pre-wrap;",
          );
        }
        return html;
    };

    return (
        <div className='fixed top-0 left-0 bg-black/30 w-full h-screen flex justify-center items-start z-[999] overflow-y-scroll pb-[200px] pt-[30px]'>
            <div className='max-w-[90%] bg-[white] rounded-2xl p-6 flex flex-col items-center lg:max-w-[700px]'>
                <h5 className="pt-3 pb-3">Membership Agreement</h5>
                <div className='mb-[16px] max-h-[300px] overflow-auto flex flex-col z-10'>
                    <div dangerouslySetInnerHTML={{ __html: prepareAgreementHtml(agreementHtml) }} />
                </div>
                <div className='w-full'>
                    <div className='flex items-center gap-2 text-[#64748B] mb-2'>
                        <input type='checkbox' id='agreeMembership' name='agreeMembership' value={agreeMembership} onChange={handleAgreement} />
                        <label htmlFor='agreeMembership' className='w-fit'>I agree to the terms and conditions of this membership agreement</label>
                    </div>
                    <div className='flex justify-between items-center mb-2'>
                        <p className='text-left text-[#64748B] mb-0'>Please Sign Below:</p>
                        <ButtonSecondary text='Reset Signature' onClick={resetSignature} />
                    </div>
                    <div className='flex justify-center border-[1px] border-[#dddddd] rounded-[5px] mb-3'>
                        <div className='w-1/2'>
                            <SignatureCanvas penColor='black'  onEnd={() => setIsSignatureEmpty(false)}
                                canvasProps={{className:'w-full h-[150px] sm:h-[200px] m-auto '}} backgroundColor='#f5f5f5' ref={(ref) => { setSignature(ref) }}/>
                        </div>
                        
                    </div>
                </div>
                <div className='w-full flex justify-start mb-4 gap-4'>
                    <input type='text' className='border border-black px-2 rounded-md h-[35px]' placeholder='Enter Coupon Code' value={couponCode} onChange={(event) => handleCouponCode(event)} disabled={couponValidated} />
                    <button className={`${accountConfig.theme}-theme primary-color font-semibold text-sm select-none ${couponValidated ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={() => handleValidateCoupon()} disabled={couponValidated}>Apply</button>
                </div>
                <div className='flex gap-8 justify-center z-20'>
                    <ButtonSecondary text='Decline' onClick={() => {setShowAgreementModal(false); setSelectedFrequency(membership.membership_payment_options)}} />
                    <ButtonPrimary text='Add to Cart' onClick={() => handleSubmit()} disabled={isSignatureEmpty || !agreeMembership} />
                </div>
            </div>
        </div>
    )
}

export default MembershipAgreementModal;