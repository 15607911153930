import React from "react";
import ClinicsStore from "stores/ClinicsStore";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import { handleClinicSelect } from "services/HttpServices";
import { useNavigate } from "react-router-dom";
import BookingStore from "stores/BookingStore";
import { shortenClinicName } from "Utils/globalActions";

const MyVerticallyCenteredModal = observer(() => {
  const { clinics, selectedClinic } = ClinicsStore;
  const { accountInfo } = DataStore;

  const navigate = useNavigate()

  const handleSelect = async (event) => {
    await handleClinicSelect(event.target.value)
    navigate('/online-booking')
  }

  return (
    <div className='fixed top-0 left-0 bg-black/30 w-full h-screen flex justify-center items-center z-[999]'>
      <div className='max-w-[500px] bg-[white] rounded-2xl p-6 flex flex-col items-center'>
        <div className="text-center pt-3 pb-3">
          <img src={accountInfo.logo_url} alt="logo" width="150" />
        </div>
        <h5 className="pt-3 pb-3">Select a location for one of our clinics!</h5>
        <div className="relative">
          <div className="absolute top-[13px] left-[13px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
              <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
              <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
            </svg>
          </div>
            <select value={selectedClinic?.id || ''} onChange={handleSelect} className="w-fit py-[10px] pr-6 pl-9 border-[0.5px] border-[#A0A6B4] rounded-[40px] appearance-none">
              <option value='' disabled>Select Clinic</option>
              {Array.from(clinics.values()).map(clinic => {
                return <option key={clinic.id} value={clinic.id}>{shortenClinicName(clinic.clinic_name)}</option>
              })}
            </select>
            <div className="absolute top-[17px] right-[13px]">
              <svg width="10" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L9 9L17 1" stroke="#525252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>
        </div>
      </div>
    </div>
  );
})

const LocationDropdown = () => {
  const { clinics, selectedClinic, showSelectionModal } = ClinicsStore;

  const handleSelect = async (event) => {
    await handleClinicSelect(event.target.value);
    if (BookingStore.step !== 1) {
      BookingStore.reset();
    }
  }

  return (
    <>
      <div className="relative">
        <div className="absolute top-[13px] left-[13px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        </div>
          <select value={selectedClinic?.id || ''} onChange={handleSelect} className="py-[10px] pr-6 pl-9 border-[0.5px] border-[#A0A6B4] rounded-[40px] appearance-none w-[255px] max-md:w-[180px]" readOnly>
            {Array.from(clinics.values()).map(clinic => {
              return <option value={clinic.id} key={clinic.id}>{shortenClinicName(clinic.clinic_name)}</option>
            })}
          </select>
          <div className="absolute top-[17px] right-[13px]">
            <svg width="10" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L9 9L17 1" stroke="#525252" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
      </div>
      {showSelectionModal && <MyVerticallyCenteredModal />}
    </>
  );
}

export default observer(LocationDropdown);
