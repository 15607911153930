import React, { useState } from 'react'
import ReadMoreModal from './ReadMoreModal'
import ButtonSecondary from 'component/Common/ButtonSecondary'
import ButtonPrimary from 'component/Common/ButtonPrimary'
import BookingStore from 'stores/BookingStore'
import { observer } from 'mobx-react-lite'
import { getAppointmentDates } from 'services/HttpServices'
import ClinicsStore from 'stores/ClinicsStore'
import { format } from 'date-fns'
import DataStore from 'stores/DataStore'
import { subdomain } from 'Utils/globalActions'
import NoAvailabilityModal from './NoAvailabilityModal'
import StaffStore from 'stores/StaffStore'
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';
import { useScrollToTop } from 'hooks/useSrollToTop'

const StepThree = () => {
    useScrollToTop();
    const [showReadMoreModal, setShowReadMoreModal] = useState(false)
    const [readMore, setReadMore] = useState({ title: '', description: '' })
    const { setStep, clinicData, bookWithFirst, setBookWithFirst, providerData, selectedServices, appointmentType, setSelectedServices, selectedProvider, setSelectedProvider } = BookingStore;
    const { selectedClinic } = ClinicsStore;
    const { isGettingData, accountConfig } = DataStore;
    const { setPreselectedProvider } = StaffStore

    const selectProvider = (provider) => {
        setBookWithFirst(false)
        setPreselectedProvider(null)
        setSelectedProvider(provider)
    }

    const selectBookWithFirst = () => {
        setPreselectedProvider(null)
        setBookWithFirst(!bookWithFirst)
        setSelectedProvider(null)
    }

    const selectReadMore = (event, provider) => {
        event.stopPropagation()
        setReadMore({ title: provider.full_name, description: provider.bio_description })
        setShowReadMoreModal(true)
    }

    const handleNext = () => {
        const firstAvailableProvider = providerData.filter((provider) => !!(parseInt(provider.schedule_count) || provider.template_count)).map((provider) => provider.id);
        GAEvent(GAEventTypes.PROVIDER_SELECTED);
        setStep(4)
        getAppointmentDates({
            params: {
                subdomain,
                provider_ids: bookWithFirst ? firstAvailableProvider : [selectedProvider.id],
                clinic_id: selectedClinic.id,
                services: selectedServices.map(service => service.id),
                appointment_type: appointmentType,
                start_date: format(new Date(), 'yyyy-MM-dd'),
                book_with_first: bookWithFirst,
                from_patient_portal: true
            }
        })
    }

    return (
        <div>
            {!isGettingData ?
                <>
                {providerData.length > 0 ?
                    <>
                        <h5 className='mb-6 text-[16px]'>Please select one provider.</h5>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6'>
                            {Boolean(clinicData.account.book_with_first) && <div className={`providerCard ${bookWithFirst ? `${accountConfig.theme}-theme card-active primary-color` : ''}`}  onClick={() => selectBookWithFirst()}>
                                <div className='flex items-center gap-3 mb-6'>
                                    {<svg width="60" height="63" viewBox="0 0 60 63" className={`${accountConfig.theme}-theme fill-primary`}  xmlns="http://www.w3.org/2000/svg">
                                        <rect width="60" height="62.5" rx="30"/>
                                        <path d="M30.0003 35.6667H24.0003C22.1396 35.6667 21.2092 35.6667 20.4521 35.8963C18.7476 36.4134 17.4137 37.7473 16.8966 39.4518C16.667 40.2089 16.667 41.1393 16.667 43M39.3337 43V35M35.3337 39H43.3337M33.3337 25C33.3337 28.3137 30.6474 31 27.3337 31C24.02 31 21.3337 28.3137 21.3337 25C21.3337 21.6863 24.02 19 27.3337 19C30.6474 19 33.3337 21.6863 33.3337 25Z" stroke="#FCFAFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>}
                                    <div>
                                        <h5 className='font-semibold'>Book with first available!</h5>
                                    </div>
                                </div>
                                <span className='text-[#334155]'> Not sure which staff member to choose? Select this option to find first available provider for your service.</span>
                            </div>}
                            {providerData.map((provider) => {
                                return (
                                    <div className={`providerCard ${selectedProvider?.id === provider.id && !bookWithFirst ? `${accountConfig.theme}-theme card-active primary-color` : ''}`} key={provider.id} onClick={() => selectProvider(provider)}>
                                        <div className='flex items-center gap-3 mb-6'>
                                            {provider.user_image_url ? <img src={provider.user_image_url} className='rounded-full h-[80px] object-cover' alt="sitelogo" width="80" /> : <div className='w-[80px] h-[80px] rounded-full bg-[#D0D5DD]'></div>}
                                            <div>
                                                <h5 className={`${accountConfig.theme}-theme primary-color font-semibold`}>{provider.bio_name}</h5>
                                                <h6>{provider.bio_title}</h6>
                                            </div>
                                        </div>
                                        <span className='text-[#334155]'>
                                            {provider.bio_description?.slice(0, 108)} {provider.bio_description?.length > 108 && '...'}
                                            {provider.bio_description?.length > 108 && <span className={`${accountConfig.theme}-theme primary-color font-semibold`} onClick={(event) => selectReadMore(event, provider)}> Read More</span>}
                                        </span>
                                    </div>
                                )
                            })}

                        </div>
                    </>
                     : <NoAvailabilityModal phoneNumber={selectedClinic?.contact_no} close={() => {setStep(2); setSelectedServices([])}} />}

                    {<div className='grid grid-cols-2 w-full sm:w-1/2 gap-4'>
                        <ButtonSecondary text='Previous' onClick={() => {setStep(2); setSelectedProvider(null)} } />
                        {(selectedProvider || bookWithFirst) && <ButtonPrimary text='Next' onClick={() => handleNext()} />}
                    </div>}
                    {showReadMoreModal && <ReadMoreModal readMore={readMore} setShowReadMoreModal={setShowReadMoreModal} />}
                </> : null
            }
        </div>
    )
}

export default observer(StepThree);