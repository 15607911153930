import API from "Utils/API";
import PPAPI from "Utils/PPAPI";
import { useEffect } from "react";
import DataStore from "stores/DataStore";
import ClinicsStore from "stores/ClinicsStore";
import CartStore from "stores/CartStore";
import { subdomain } from "Utils/globalActions";
import { useNavigate } from "react-router-dom";

const useSessionHandShake = () => {
  const navigate = useNavigate()

  const handShake = async () => {
    try {
      const previousSessionId = localStorage.getItem('sessionId');

      const hash = sessionStorage.getItem('session-hash')

      const handShakeResponse = await API.post('hand-shake', {
        subdomain,
        sessionId: previousSessionId,
        hash
      })
      const sessionId = handShakeResponse.data.data.sessionId
      localStorage.setItem('sessionId', sessionId)

      CartStore.setTotalItems(handShakeResponse.data.data.cartTotalItems)

      const config = await API.get('config')
      DataStore.initialize(config.data.data)

      try {
        await PPAPI.get('auth-patient')

        const body = new FormData()
        body.append('sessionId', sessionId)
        await PPAPI.post('auth-patient', body)

        const patient = await API.get('patient')
        DataStore.setPatientInfo(patient.data.data)
      } catch (error) {
      }

      loadClinics()
    } catch (error) {
      return navigate('/online-booking/redirect/dashboard')
    }
  }

  const loadClinics = async () => {
    try {
      const response = await API.get('clinics');
      ClinicsStore.setClinics(response.data.data.clinics);
      if (response.data.data.selectedClinicId !== null) {
        ClinicsStore.setSelectedClinic(response.data.data.selectedClinicId);
      }
    } catch (error) {
    }
  }

  useEffect(() => {

    handShake()

    return () => {
      DataStore.reset()
      ClinicsStore.reset()
    }
  }, [])
}

export default useSessionHandShake;
