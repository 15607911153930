import React from "react";
import DataStore from "stores/DataStore";

const LandingPageFailed = () => {
  const { isGettingData } = DataStore;
  return (
    <div className="h-screen flex justify-center">
      {!isGettingData && (
        <div className="relative top-[300px] text-center">
          <div className="flex justify-center mb-6">
            <svg
              className="w-[70px] h-[70px]"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M512 853.333333a341.333333 341.333333 0 1 1 341.333333-341.333333 341.333333 341.333333 0 0 1-341.333333 341.333333z m0-768a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z m110.506667 256L512 451.84 401.493333 341.333333 341.333333 401.493333 451.84 512 341.333333 622.506667 401.493333 682.666667 512 572.16 622.506667 682.666667 682.666667 622.506667 572.16 512 682.666667 401.493333z"
                fill="#B00020"
              />
            </svg>
          </div>
          <h1>Payment Failed!</h1>
          <h4 className="m-b-40">The request has been declined.</h4>
        </div>
      )}
    </div>
  );
};

export default LandingPageFailed;
