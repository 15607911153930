export const getToday = () => {
  return new Date().getDay()
}

export const formatTimeAmPm = (time) => {
  let hour = time.split(':')[0]
  let minutes = time.split(':')[1]

  if (parseInt(hour) < 12) {
    return `${hour}:${minutes} AM`
  }
  if (parseInt(hour) === 12) {
    return `${hour}:${minutes} PM`
  }
  return `${hour < 22 ? '0' : ''}${hour - 12}:${minutes} PM`
}

export const subdomain = process.env.REACT_APP_LOCAL_DEV_CLINIC || window.location.host.split('.')[0] || null

export const formatDescriptionText = (description) => {
  return <div dangerouslySetInnerHTML={{ __html: description?.replaceAll('\n', '<br />') }} />
}

export const formatCurrencyAmount = (currencyCode, amount) => {
  return new Intl.NumberFormat('en', { style: 'currency', currency: currencyCode }).format(amount)
}

export const addPhonePrefix = (phone) => {
  if (typeof phone === "string" || typeof phone === "number") {
    return `+${String(phone).replace(/\+/g, "")}`;
  }
  return phone;
}

export const shortenClinicName  = (str, limit = 45) => {
    if (typeof str === "string" && typeof limit === "number" && limit > 0 && str.length >= limit) {
        const half = Math.floor(limit / 2);
        return `${str.slice(0, half)}...${str.slice(half * -1)}`;
    }
    return str;
};

export const composeUrl = (url) => {
  if (!url) {
    return "";
  }

  if (url.startsWith("http") || url.startsWith("https")) {
    return url;
  }

  return `https://${url}`;
}
