import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import '../../../css/Calendar.css'
import CancellationPolicyModal from './CancellationPolicyModal';
import { format, parse } from 'date-fns'
import { bookAppointment, getAppointmentDates, getCancellationPolicy } from 'services/HttpServices';
import ClinicsStore from 'stores/ClinicsStore';
import BookingStore from 'stores/BookingStore';
import { observer } from 'mobx-react-lite';
import ButtonSecondary from 'component/Common/ButtonSecondary';
import ButtonPrimary from 'component/Common/ButtonPrimary';
import DataStore from 'stores/DataStore';
import '../../../css/Themes.scss'
import { addPhonePrefix, formatTimeAmPm, subdomain } from 'Utils/globalActions';
import BookingErrorModal from './BookingErrorModal';
import { toast } from 'react-toastify';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';
import { useScrollToTop } from 'hooks/useSrollToTop';
import { TIME_FORMATS_MAP } from '../../../config';

const StepFour = () => {
    useScrollToTop();
    const [calendarValue, setCalendarValue] = useState(new Date())
    const [formattedDate, setFormattedDate] = useState(format(new Date(), 'LLL dd, yyyy'))
    const [apiDate, setApiDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const { setStep, clinicPosEnabled, contactInfo, providerData, bookWithFirst, clinicData, selectedServices, appointmentType, selectedProvider, setSelectedProvider, appointmentDates, selectedHour, setSelectedHour, setSelectedDateAndHour, setSelectedApiDate, cancellationPolicyText, consent } = BookingStore;
    const { selectedClinic } = ClinicsStore;
    const { accountConfig, accountInfo } = DataStore;
    const [hours, setHours] = useState([])
    const [showCancellationPolicyModal, setShowCancellationPolicyModal] = useState(false)
    const [showBookingErrorModal, setShowBookingErrorModal] = useState(false)
    const [bookingError, setBookingError] = useState('')
    const availableDays = Object.keys(appointmentDates?.first_available || {})
    const firstAvailableProviderIds = providerData.filter((provider) => !!(parseInt(provider.schedule_count) || provider.template_count)).map((provider) => provider.id);

    const handleCalendarValue = (date) => {
        const formattedDate = format(date, 'LLL dd, yyyy')
        setCalendarValue(date)
        setFormattedDate(formattedDate)
        setApiDate(format(date, 'yyyy-MM-dd'))
        setSelectedHour('')
        if(bookWithFirst) {
            setSelectedProvider(null)
        }
    }

    const handleHour = (hour) => {
        setSelectedHour(hour)
        const selectedHourProviders = []
        firstAvailableProviderIds.forEach(provider => {
            if(appointmentDates.available_days[provider] && appointmentDates.available_days[provider][apiDate] && appointmentDates.available_days[provider][apiDate].includes(hour)) {
                selectedHourProviders.push(provider)
            }
        })
        setSelectedProvider(providerData.find(provider => provider.id === selectedHourProviders[0]))
    }

    const onActiveStartDateChangeHandler = ({ activeStartDate }) => {
        getAppointmentDates({
            params: {
                subdomain,
                provider_ids: bookWithFirst ? firstAvailableProviderIds : [selectedProvider.id],
                clinic_id: selectedClinic.id,
                services: selectedServices.map(service => service.id),
                appointment_type: appointmentType,
                start_date: format(activeStartDate, 'yyyy-MM-dd'),
                book_with_first: bookWithFirst,
                from_patient_portal: true
            }
        })
    };

    useEffect(() => {
            setHours(((appointmentDates.first_available || {}))[apiDate]?.merged)
    }, [apiDate, availableDays])

    const handleNext = () => {
        const isSomeServiceCardCaptureEnabled = selectedServices.some(s => s?.is_service_free === 0);
        if (isSomeServiceCardCaptureEnabled && clinicPosEnabled && Boolean(clinicData.account.pos_enabled) && clinicData.account.cancellation_policy_status) {
            setShowCancellationPolicyModal(true)
        } else {
            book()
        }
    }
   
    const book = async () => {
        GAEvent(GAEventTypes.TIME_SLOT_SELECTED);
        const noFreeServices = selectedServices.some(service => parseFloat(service.price) > 0.5 && service.free_consultation === 0);
        const isSomeServiceCardCaptureEnabled = selectedServices.some(service => service.is_service_free === 0);
        
        setSelectedApiDate(apiDate);
        const time = parse(selectedHour, "HH:mm:ss", new Date());
        
        setSelectedDateAndHour(
          `${format(calendarValue, 'EEE LLL dd, yyyy')} at ${format(time, TIME_FORMATS_MAP?.[accountInfo.time_format] || "hh:mm a")}`
        );

        if (clinicPosEnabled && Boolean(clinicData.account.pos_enabled) && ((clinicData.account.cancellation_policy_status && isSomeServiceCardCaptureEnabled) || noFreeServices)) {
            setStep(5)
        } else {
            // TODO move this logic into one place to avoid different behaviours for when booking can be done without payments
            const formData = {
                patient_id: '',
                email: contactInfo.email,
                firstname: contactInfo.firstname,
                lastname: contactInfo.lastname,
                phone_number: addPhonePrefix(contactInfo.phone_number),
                clinic_id: selectedClinic.id,
                provider_id: selectedProvider.id,
                date: apiDate,
                time: selectedHour,
                services: selectedServices.map(service => service.id),
                appointment_type: appointmentType,
                payment_token: null,
                hpp_card_token: null,
                subdomain,
                pos_gateway: clinicData.account.pos_gateway,
                pos_enabled: clinicData.account.pos_enabled,
                cancellation_policy_status: clinicData.account.cancellation_policy_status,
                appointment_notes: contactInfo.appointment_notes,
                consent
            }
            try {
                const bookingData = await bookAppointment(formData)
                BookingStore.setBookAppointment(bookingData.data)
                GAEvent(GAEventTypes.APPOINTMENT_BOOKED);
                setStep(6)
              } catch (error) {
                toast.dismiss()
                setShowBookingErrorModal(true)
                setBookingError(error.response.data.message)
              }
        }
    }

    useEffect(() => {
        if (clinicData.account.cancellation_policy_status) {
            getCancellationPolicy()
        }
    }, [])

    const selectedClinicBooking = clinicData.all_clinics?.find(clinic => clinic.id === selectedClinic.id)

    const getTimezoneName = () => {
        const pattern = /\([a-zA-z ]*[?\-|\+]\d*\:\d*\)\s/;
        return selectedClinicBooking.timezone_full_info
            ? selectedClinicBooking.timezone_full_info.trim().replace(pattern, "")
            : "";
    }

    const handlePrevious = () => {
        setSelectedHour('')
        setSelectedProvider(null)
        setStep(3)
    }

    return (
        <div>
            <h5 className='mb-6 text-[16px]'>Please select date & time for your appointment</h5>
            {Boolean(getTimezoneName()) && <h5 className='mb-6 text-[16px]'>All times displayed in {getTimezoneName()} time</h5>}
            <div className='grid grid-cols-1 sm:grid-cols-2 mb-6'>
                <div className='relative'>
                    <Calendar
                        value={calendarValue}
                        onClickDay={handleCalendarValue}
                        minDate={new Date()}
                        onActiveStartDateChange={onActiveStartDateChangeHandler}
                        tileDisabled={({ date }) => !appointmentDates?.unique_dates?.includes(format(date, 'yyyy-MM-dd'))}
                        showNeighboringMonth={false}
                        view="month"
                    />
                    <input type='text' className='calendarInput absolute top-[90px] left-[32px]' name='date' value={formattedDate} readOnly />
                </div>
                {<div className='p-[24px] max-h-[435px] overflow-y-auto'>
                    <div className='grid grid-cols-3 gap-[16px]'>
                    {hours?.length > 0 && hours.map(hour => {
                        if (hour) {
                            return (
                                <div 
                                  className={`appointmentHour ${selectedHour === hour ? `${accountConfig.theme}-theme card-active primary-color` : ''}`} 
                                  key={hour} 
                                  onClick={() => handleHour(hour)}
                                >
                                  {format(
                                    parse(
                                      hour, 
                                      "HH:mm:ss", 
                                      new Date()
                                    ), 
                                    TIME_FORMATS_MAP?.[accountInfo.time_format] || "hh:mm a")}
                                </div>
                            )
                        }
                        return null;
                    })}
                    </div>
                </div>}
            </div>
            {<div className='grid grid-cols-2 w-full sm:w-1/2 gap-4'>
                <ButtonSecondary text='Previous' onClick={() => handlePrevious()} />
                {selectedHour && <ButtonPrimary text='Next' onClick={() => handleNext()} />}
            </div>}
            {showCancellationPolicyModal && <CancellationPolicyModal text={cancellationPolicyText} setShowCancellationPolicyModal={setShowCancellationPolicyModal} handleAccept={book} />}
            {showBookingErrorModal && <BookingErrorModal bookingError={bookingError} setShowBookingErrorModal={setShowBookingErrorModal} />}
        </div>
    )
}

export default observer(StepFour);
