import ReactGA from "react-ga4";

let eventQueue = [];

const EventCategory = {
  BOOKING: "Booking",
  MEMBER: "Member",
  SERVICES: "Services",
  STAFF: "Staff",
  EGIFT: "E_Gift",
  PROMOTION: "Promotion",
  CHECKOUT: "Checkout",
};

export const GAEventTypes = {
  // Booking
  BOOKING_STARTED: {
    category: EventCategory.BOOKING,
    action: "Booking_Started",
  },
  PHONE_VERIFIED: { category: EventCategory.BOOKING, action: "Phone_Verified" },
  SERVICES_SELECTED: {
    category: EventCategory.BOOKING,
    action: "Services_Selected",
  },
  PROVIDER_SELECTED: {
    category: EventCategory.BOOKING,
    action: "Provider_Selected",
  },
  TIME_SLOT_SELECTED: {
    category: EventCategory.BOOKING,
    action: "Date_Time_Selected",
  },
  APPOINTMENT_BOOKED: {
    category: EventCategory.BOOKING,
    action: "Appointment_Booked",
  },

  // Member
  MEMBER_PAGE_VIEW: {
    category: EventCategory.MEMBER,
    action: "Membership_Page",
  },
  MEMBER_INIT: {
    category: EventCategory.MEMBER,
    action: "Membership_Init",
  },
  MEMBER_ADDED: {
    category: EventCategory.MEMBER,
    action: "Membership_Added",
  },

  // Services
  SERVICES_PAGE_VIEW: {
    category: EventCategory.SERVICES,
    action: "Services_Page",
  },

  // Staff
  STAFF_PAGE_VIEW: {
    category: EventCategory.STAFF,
    action: "Staff_Page",
  },

  // E-Gift
  EGIFT_PAGE_VIEW: {
    category: EventCategory.EGIFT,
    action: "Gift_Page",
  },
  EGIFT_ADDED: {
    category: EventCategory.EGIFT,
    action: "Gift_Card_Added",
  },

  // Promo
  PROMOTION_PAGE_VIEW: {
    category: EventCategory.PROMOTION,
    action: "Promotion_Page",
  },
  PROMOTION_ADDED: {
    category: EventCategory.PROMOTION,
    action: "Promotion_Added",
  },

  // Checkout
  CHECKOUT: {
    category: EventCategory.CHECKOUT,
    action: "Cart_Checkout",
  },
};

export const GAEvent = (event) => {
  if (ReactGA.isInitialized) {
    const { category, action, ...rest } = event;
    ReactGA.event({
      category,
      action,
      label: action,
      ...rest,
    });
  } else {
    eventQueue.push(event);
  }
};

export const initializeGA = (gaKey) => {
  ReactGA.initialize(gaKey);

  eventQueue.forEach((event) => {
    GAEvent(event);
  });

  eventQueue = [];
};
