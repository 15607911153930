import React from 'react'
import ButtonPrimary from '../Common/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import { formatDescriptionText } from 'Utils/globalActions';
import ServicesStore from 'stores/ServicesStore';
import { observer } from 'mobx-react-lite';
import ClinicsStore from 'stores/ClinicsStore';

const ServiceBox = (props) => {
    const { service } = props
    const { name, description, duration } = service;
    const { setPreselectedService } = ServicesStore
    const { selectedClinic } = ClinicsStore;

    const navigate = useNavigate()

    const handleBooking = () => {
        setPreselectedService(service)
        navigate('/online-booking/book-appointment')
    }
    
    return (
        <div className='p-8 border border-[#E2E8F0] rounded-lg mb-4'>
            <h3 className='text-[20px] mb-4'>{name}</h3>
            {formatDescriptionText(description)}
            <div className='flex justify-between items-center border-t pt-[20px] mt-4'>
                <div className='flex items-center font-semibold text-[#475569]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#334155" className="bi bi-clock" viewBox="0 0 16 16">
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                    {duration} minutes
                </div>
                {Boolean(selectedClinic?.is_available_online) && <ButtonPrimary text='Book Now' onClick={() => handleBooking()} />}
            </div>
        </div>
    )
}

export default observer(ServiceBox);