import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite';
import DataStore from 'stores/DataStore';
import StripePayment from 'component/Payment/StripePayment'
import { getStripePaymentIntent, chargeClearentCC, cancelInvoice, getInvoice } from 'services/HttpServices';
import { useNavigate } from 'react-router-dom';
import CartStore from 'stores/CartStore';

const CartPayment = () => {
  const { accountInfo, accountConfig } = DataStore;
  const { invoiceData, setInvoiceData } = CartStore

  const [stripeClientSecret, setStripeClientSecret] = useState('')
  const [isStripeSetup, setIsStripeSetup] = useState(false)
  const navigate = useNavigate()

  const handleContinueShopping = async (event) => {
    event.preventDefault();
    await cancelInvoice()
    navigate('/online-booking/cart')
  }

  const handleLoadInvoice = async () => {
    try {
      const response = await getInvoice()
      setInvoiceData(response.data.data)
    } catch (error) {
      navigate('/online-booking/cart')
    }
  }

  useEffect(() => {
    if (accountInfo.pos_gateway === undefined) {
      return;
    }
    if (window.Clearent && accountInfo.pos_gateway === 'clearent') {
      window.Clearent.reset()
      window.ClearentOnSuccess = async (responseRaw, ResponseJSON) => {
        try {
          await chargeClearentCC(ResponseJSON.payload.tokenResponse['token-id']);
          navigate('/online-booking/cart/check-invoice-status')
        } catch (error) {
          navigate('/online-booking/cart')
        }
      }
      window.ClearentOnError = (responseRaw, ResponseJSON) => {
        console.log(responseRaw, ResponseJSON);
      }
      window.ClearentValidation = (messages) => {
        console.log(messages);
      }
      window.Clearent.setProperty('submit-pay-method-button-text', 'Proceed')
      window.Clearent.setProperty('show-inline', 'true')
      window.Clearent.payButton({
        'pk': accountInfo.clearent_publishable_key,
        'request-type': 'TOKEN-ONLY',
        'pay-button-parent': 'paymentDiv'
      });
      window.ClearentOnPopupOpened = () => {
        const formButtons = document.getElementById('Clearent-form-controls')
        formButtons.classList.add('flex', 'justify-between', 'flex-row-reverse')
        const backButton = document.createElement('button')
        backButton.classList.add(`${accountConfig.theme}-theme`, 'secondary-button', 'py-2', 'px-6', 'rounded-[30px]', '!font-semibold', 'whitespace-nowrap', 'cursor-pointer', 'text-sm', 'select-none')
        backButton.textContent = '< Back to Cart'
        backButton.onclick = (event) => handleContinueShopping(event)
        formButtons.appendChild(backButton)
      }
    }
    if (accountInfo.pos_gateway === 'stripe') {
      getStripePaymentIntent().then((response) => {
        setStripeClientSecret(response.data.client_secret)
        setIsStripeSetup(response.data.is_setup)
      })
    }
  }, [accountInfo.pos_gateway])

  useEffect(() => {
    if (invoiceData === null) {
      handleLoadInvoice()
    }
  }, [invoiceData])

  return (
    <>
      {stripeClientSecret && <StripePayment clientSecret={stripeClientSecret} isSetup={isStripeSetup} />}
      {accountInfo.pos_gateway === 'clearent' && <div id='paymentDiv'></div>}
    </>
  )
}

export default observer(CartPayment);
