
export const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const ItemTypes = {
  GiftCard: 'gift-card',
  Promotion: 'promotion',
  Membership: 'membership',
}

export const MembershipTypes = {
  Monthly: 'monthly',
  Yearly: 'yearly',
  Both: 'both',
}

export const MAINTENANCE_MODE = false