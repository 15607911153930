import React, { useEffect } from "react";
import Leftside from "../Leftside";
import useGetMemberships from "../../hooks/useGetMemberships";
import RightContainer from "../RightContainer";
import MembershipBox from "../Memberships/MembershipBox";
import DataStore from "stores/DataStore";
import { observer } from "mobx-react-lite";
import MembershipsStore from "stores/MembershipsStore";
import "../../css/Themes.scss";
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";

const MembershipsPage = () => {
  useGetMemberships();

  const { memberships } = MembershipsStore;
  const { accountConfig } = DataStore;

  useEffect(() => {
    GAEvent(GAEventTypes.MEMBER_PAGE_VIEW);
  }, []);

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside />
            <RightContainer title="Memberships">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {Array.from(memberships.values()).map((membership) => (
                  <MembershipBox key={membership.id} membership={membership} />
                ))}
              </div>
            </RightContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(MembershipsPage);
