import { composeUrl } from "Utils/globalActions";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";

export const Footer = observer(() => {
  const config = DataStore.accountConfig;

  if (!config.privacy) {
    return null;
  }

  return (
    <footer className={`px-6 py-4 secondary-bg ${config.theme}-theme border-t-[1px] text-right`}>
      {config.privacy && (
        <a href={composeUrl(config.privacy)} target="_blank" rel="noreferrer noopener" className="font-light text-gray-600 text-sm">
          Privacy Policy
        </a>
      )}
    </footer>
  );
});
