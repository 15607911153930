import { makeAutoObservable } from "mobx"

class GiftCardStore {
  giftCards = new Map()
  giftCardFormError = {}

  constructor () {
    makeAutoObservable(this)
  }

  setGiftCards = (giftCards) => {
    this.giftCards = new Map(giftCards.map((giftCard) => [giftCard.id, giftCard]));
  }

  setGiftCardFormError = (value) => {
    this.giftCardFormError = value
  }
}

export default new GiftCardStore();