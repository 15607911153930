import React, { useEffect } from "react";
import Leftside from "../Leftside";
import useGetPromotions from "../../hooks/useGetPromotions";
import RightContainer from "../RightContainer";
import PromotionCard from "../Promotions/PromotionCard";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import PromotionsStore from "stores/PromotionsStore";
import '../../css/Themes.scss'
import { GAEvent, GAEventTypes } from "Utils/GoogleAnalytics";

const PromotionsPage = () => {

  const { promotions } = PromotionsStore;
  const { accountConfig } = DataStore;

  useGetPromotions();

  useEffect(() => {
    GAEvent(GAEventTypes.PROMOTION_PAGE_VIEW);
  }, [])

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div
            className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside/>
            <RightContainer title='Promotions'>
              <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:grid-cols-2">
                {Array.from(promotions.values()).map(promotion => {
                  return <PromotionCard
                    key={promotion.id}
                    id={promotion.id}
                    image={promotion.image_booking_portal_url}
                    promotion={promotion}
                  />
                })}
              </div>
            </RightContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(PromotionsPage);