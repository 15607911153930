import React, { useEffect, useState } from 'react'
import BookingStore from 'stores/BookingStore';
import ClinicsStore from 'stores/ClinicsStore';
import DataStore from 'stores/DataStore';
import { bookAppointment, cancelBookingStripeSetupIntent, getBookingStripeSetupIntent } from 'services/HttpServices';
import { observer } from 'mobx-react-lite';
import { addPhonePrefix, formatCurrencyAmount, subdomain } from 'Utils/globalActions';
import BookingErrorModal from './BookingErrorModal';
import { toast } from 'react-toastify';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';
import { useScrollToTop } from 'hooks/useSrollToTop';
import StripePayment from 'component/Payment/StripePayment';

const BookingPayment = (props) => {
  const {
    setStep,
    clinicData,
    contactInfo,
    selectedProvider,
    selectedApiDate,
    selectedHour,
    selectedServices,
    appointmentType,
    authText,
    consent
  } = BookingStore;

  useScrollToTop();
  const { accountInfo } = DataStore;
  const { selectedClinic } = ClinicsStore;

  const [showBookingErrorModal, setShowBookingErrorModal] = useState(false)
  const [bookingError, setBookingError] = useState('')
  const [stripeClientSecret, setStripeClientSecret] = useState(null)
  const [stripeSetupIntentId, setStripeSetupIntentId] = useState(null)

  const bookingAppointment = async (paymentToken, hppCardToken) => {
    // TODO move this logic into one place along with stepfour implementation to avoid different implamentations
    const formData = {
      patient_id: '',
      email: contactInfo.email,
      firstname: contactInfo.firstname,
      lastname: contactInfo.lastname,
      phone_number: addPhonePrefix(contactInfo.phone_number),
      clinic_id: selectedClinic.id,
      provider_id: selectedProvider.id,
      date: selectedApiDate,
      time: selectedHour,
      services: selectedServices.map(service => service.id),
      appointment_type: appointmentType,
      zipcode: null,
      payment_token: stripeSetupIntentId || null,
      hpp_card_token: hppCardToken,
      appointment_notes: contactInfo.appointment_notes,
      subdomain,
      pos_gateway: clinicData.account.pos_gateway,
      pos_enabled: clinicData.account.pos_enabled,
      cancellation_policy_status: clinicData.account.cancellation_policy_status,
      consent
    }
    try {
      const bookingData = await bookAppointment(formData)
      BookingStore.setBookAppointment(bookingData.data)
      GAEvent(GAEventTypes.APPOINTMENT_BOOKED);
      setStep(6)
    } catch (error) {
      toast.dismiss()
      setShowBookingErrorModal(true)
      setBookingError(error.response.data.message)
      cancelAndCreateNewStripeSetupIntent()
    }
  }

  const createStripeSetupIntent = () => {
    const payload = {
      subdomain,
      patient_email: contactInfo.email,
      patient_firstname: contactInfo.firstname,
      patient_lastname: contactInfo.lastname,
      patient_phone: addPhonePrefix(contactInfo.phone_number),
      clinic_id: selectedClinic.id,
    }

    getBookingStripeSetupIntent(payload).then((response) => {
      setStripeClientSecret(response.data.data.clientSecret)
      setStripeSetupIntentId(response.data.data.setupIntentId)
    })
  }

  const cancelAndCreateNewStripeSetupIntent = () => {
    setStripeClientSecret(null)
    setStripeSetupIntentId(null)
    cancelBookingStripeSetupIntent(stripeSetupIntentId).then((response) => {
      createStripeSetupIntent()
    })
  }

  useEffect(() => {
    if (window.Clearent && accountInfo.pos_gateway === 'clearent') {
      window.Clearent.reset()
      window.ClearentOnSuccess = (responseRaw, ResponseJSON) => {
        bookingAppointment(null, ResponseJSON.payload.tokenResponse['token-id'])
      }
      window.Clearent.setProperty('submit-pay-method-button-text', 'Complete My Booking')
      window.Clearent.setProperty('show-inline', 'true')
      window.Clearent.payButton({
        'pk': accountInfo.clearent_publishable_key,
        'request-type': 'TOKEN-ONLY',
        'pay-button-parent': 'paymentDiv'
      });
    }

    if (accountInfo.pos_gateway === 'stripe') {
      createStripeSetupIntent()
    }
  }, [])

  const totalDeposit = selectedServices.reduce((acc, curr) => acc + parseFloat(curr.price), 0)
  const currencyCode = accountInfo.currency_code || 'USD'

  return (
    <>
      <p htmlFor='agreement'>{authText}</p>
      {totalDeposit > 0 && <p>You will be charged for {formatCurrencyAmount(currencyCode, totalDeposit.toFixed(2))} deposit fee(s) of the service(s) you booked!</p>}
      {accountInfo.pos_gateway === 'clearent' && <div id='paymentDiv'></div>}
      {accountInfo.pos_gateway === 'stripe' &&
        stripeClientSecret && <StripePayment clientSecret={stripeClientSecret} handleBookAppointment={bookingAppointment} isSetup={true} />}
      {showBookingErrorModal && <BookingErrorModal bookingError={bookingError} setShowBookingErrorModal={setShowBookingErrorModal}/>}
    </>
  )
}

export default observer(BookingPayment);
