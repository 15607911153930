import React from "react";

const Googlemap = (props) => {
    const { selectedClinicinfo } = props;

    return ( 
        <div>
            <iframe src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&q=${selectedClinicinfo?.address},${selectedClinicinfo?.clinic_city},${selectedClinicinfo?.clinic_state},${selectedClinicinfo?.clinic_zipcode},${selectedClinicinfo?.country}`}
            width="100%" height="177"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map">
            </iframe>
        </div>

        );
    }
    
export default Googlemap;