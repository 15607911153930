import Leftside from "component/Leftside";
import RightContainer from "component/RightContainer";
import { observer } from "mobx-react-lite";
import DataStore from "stores/DataStore";
import '../../css/Themes.scss'
import CartPayment from "component/Cart/CartPayment";

const CartPaymentPage = (props) => {

  const { accountConfig } = DataStore;

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div
            className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside />
            <RightContainer title='Payment'>
              <CartPayment />
            </RightContainer>
          </div>
        </div>
      </div>
    </>


  );
}

export default observer(CartPaymentPage);