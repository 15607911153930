import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ButtonPrimary from 'component/Common/ButtonPrimary';
import { toast } from 'react-toastify';
import DataStore from 'stores/DataStore';
import ButtonSecondary from 'component/Common/ButtonSecondary';
import { cancelInvoice, updateStripePaymentIntent } from 'services/HttpServices';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CartStore from 'stores/CartStore';
import { formatCurrencyAmount } from 'Utils/globalActions';
import BookingStore from 'stores/BookingStore';

const StripePaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { patientInfo, fullName, accountInfo } = DataStore
  const { bookingFullName, contactInfo } = BookingStore
  const { invoiceData } = CartStore
  const { isSetup, handleBookAppointment } = props;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true)

    const options = {
      elements,
    }

    if(handleBookAppointment) {
      options.redirect = 'if_required'
    } else {
      options.confirmParams = {
        return_url: `${window.location.origin}/online-booking/cart/stripe-completed-payment`
      }
    }

    const result = isSetup
        ? await stripe.confirmSetup(options)
        : await stripe.confirmPayment(options);

    if (result.error) {
      toast.error(result.error.message)
    } else {
      if(handleBookAppointment) {
        handleBookAppointment()
      }
    }

    setIsLoading(false)
  };

  const handleContinueShopping = async (event) => {
    event.preventDefault();
    await cancelInvoice()
    navigate('/online-booking/cart')
  }

  const handleChange = async (event) => {
    if (!Boolean(handleBookAppointment) && event.elementType === 'payment') {
      try {
        await updateStripePaymentIntent(event.value.type)
      } catch (error) {
      }
    }
  }

  const buttonText = () => {
    const currencyCode = accountInfo.currency_code || 'USD'
    if(invoiceData?.total_amount > 0 && !Boolean(handleBookAppointment)) {
      return `Pay ${formatCurrencyAmount(currencyCode, parseFloat(invoiceData?.total_amount).toFixed(2))}`
    }
    return 'Proceed'
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onChange={handleChange}
                      options={{
                        defaultValues: {
                          billingDetails: {
                            email: contactInfo.email || patientInfo.email,
                            name: bookingFullName.trim() || fullName,
                          }
                        }
                      }}/>
      <div className='mt-4 mb-2 flex justify-between'>
        {!Boolean(handleBookAppointment) && <ButtonSecondary text='< Back to Cart' onClick={handleContinueShopping}/>}
        <ButtonPrimary disabled={!stripe || isLoading} text={isLoading ? 'Processing...' : buttonText()}/>
      </div>
    </form>
  );
}

const StripePayment = (props) => {
  const { clientSecret, isSetup, handleBookAppointment } = props;

  const appearance = {
    rules: {
      '.Label': {
        textTransform: 'capitalize',
      },
    }
  }

  const options = {
    clientSecret,
    appearance
  }
  const stripePromise = loadStripe(DataStore.accountInfo.stripe_publishable_key);

  return (
    <>
      {clientSecret !== null &&
        <Elements stripe={stripePromise} options={options}>
          <StripePaymentForm isSetup={isSetup} handleBookAppointment={handleBookAppointment}/>
        </Elements>
      }
    </>
  );
}

export default StripePayment;
