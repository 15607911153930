import React, { useEffect, useState, useRef } from 'react'
import Leftside from '../Leftside';
import RightContainer from '../RightContainer';
import { useNavigate, useParams } from 'react-router-dom';
import GiftCardBox from '../GiftCards/GiftCardBox';
import GiftCardForm from '../GiftCards/GiftCardForm';
import ButtonPrimary from '../Common/ButtonPrimary';
import ButtonSecondary from '../Common/ButtonSecondary';
import Calendar from 'react-calendar';
import '../../css/Calendar.css'
import useOnClickOutside from 'hooks/useOnClickOutside';
import { addItemToCart } from 'services/HttpServices';
import { ItemTypes } from "Utils/constants";
import DataStore from 'stores/DataStore';
import GiftCardStore from "stores/GiftCardStore";
import '../../css/Themes.scss'
import { observer } from 'mobx-react-lite';
import { addDays, format } from 'date-fns'
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const GiftCardPurchasePage = () => {
  const routeParams = useParams()
  const id = Number(routeParams.id)
  const giftCard = GiftCardStore.giftCards.get(id) || {}

  const minDate = addDays(new Date(), 1)
  const { accountConfig, patientInfo } = DataStore;
  const [selectedDate, setSelectedDate] = useState(minDate)
  const [formattedDate, setFormattedDate] = useState(`${minDate.toDateString().slice(4, 10)}, ${minDate.getFullYear()}`)
  const [showCalendar, setShowCalendar] = useState(false)
  const [giftCardForm, setGiftCardForm] = useState({ recipient_name: '', recipient_email: '', buyer_name:'', buyer_email: '' })
  const [inputError, setInputError] = useState([])
  const { giftCardFormError, setGiftCardFormError } = GiftCardStore

  const calendarRef = useRef()
  const navigate = useNavigate()

  useOnClickOutside(calendarRef, () => setShowCalendar(false))

  const handleCalendarValue = (date) => {
    const formattedDate = `${date.toDateString().slice(4, 10)}, ${date.getFullYear()}`
    setSelectedDate(date)
    setFormattedDate(formattedDate)
  }

  const handleGiftCardForm = (event) => {
    const inputErrors = giftCardFormError
    setGiftCardForm({ ...giftCardForm, [event.target.name]: event.target.value })
    delete inputErrors[`gift_card_props.${event.target.name}`]
    setGiftCardFormError({ ...giftCardFormError, inputErrors })
  }

  const handleSubmitButton = (delivery) => {
    const { recipient_name, recipient_email, buyer_name, buyer_email, personal_message } = giftCardForm;
    setShowCalendar(false)

    addItemToCart({
      item_id: id,
      item_type: ItemTypes.GiftCard,
      gift_card_props: {
        delivery,
        recipient_name: recipient_name,
        recipient_email: recipient_email,
        buyer_name: buyer_name,
        buyer_email: buyer_email,
        personal_message: personal_message,
        delivery_date: format(selectedDate, 'yyyy-MM-dd'),
      }
    }, () => {
      GAEvent(GAEventTypes.EGIFT_ADDED);
      navigate('/online-booking/giftcards')
    })
  }

  useEffect(() => {
    if (!(id > 0) || !GiftCardStore.giftCards.has(id)) {
      navigate('/online-booking/giftcards')
    }
    if (patientInfo.email) {
      setGiftCardForm({ ...giftCardForm, buyer_email: patientInfo.email, buyer_name: `${patientInfo.firstname} ${patientInfo.lastname}`})
    }
  }, [])

  useEffect(() => {
    return () => GiftCardStore.setGiftCardFormError({})
  }, [])

  return (
    <div className={`${accountConfig.theme}-theme secondary-bg`}>
      <div className="lg:px-4 py-4 min-h-screen max-h-full">
        <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
          <Leftside/>
          <RightContainer title='eGift Cards'>
            <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-6'>
              <div className='p-4'>
                <GiftCardBox giftCard={giftCard}/>
              </div>
            </div>
            <div className='p-6 border border-[#F1F5F9] rounded-lg mb-4'>
              <GiftCardForm inputError={inputError} giftCardForm={giftCardForm} handleGiftCardForm={handleGiftCardForm}/>
              <div className='mb-4'>
                <h6>
                  Gift Card Policy:
                </h6>
                <span className='whitespace-pre-line'>{giftCard.policy}</span>
              </div>
              <div className='flex flex-col gap-4 sm:flex-row relative'>
                <ButtonPrimary text='Send Instantly' onClick={() => handleSubmitButton('instant')}/>
                <ButtonSecondary text='Send on Future Date' onClick={() => setShowCalendar(true)}/>
                {showCalendar && <div className='calendar absolute custom-calendar' ref={calendarRef}>
                  <Calendar
                    value={selectedDate}
                    onClickDay={handleCalendarValue}
                    minDate={minDate}
                  />
                  <input type='text' className='calendarInput absolute top-[90px] left-[32px]' name='date'
                         value={formattedDate} readOnly/>
                  <div className='flex justify-start gap-4 px-8 pt-3 border-t border-t-[#CBD5E1]'>
                    <ButtonPrimary text='Send' onClick={() => handleSubmitButton('future')}/>
                    <ButtonSecondary text='Cancel' onClick={() => setShowCalendar(false)}/>
                  </div>
                </div>}
              </div>
            </div>
          </RightContainer>
        </div>
      </div>
    </div>
  )
}

export default observer(GiftCardPurchasePage);
