import { useEffect } from 'react'
import API from "Utils/API";
import CartStore from 'stores/CartStore'
import DataStore from 'stores/DataStore'

export const getCartItems = async () => {
  try {
    const patient = await API.get('patient')
    DataStore.setPatientInfo(patient.data.data)
  } catch (error) {
    DataStore.setPatientInfo({})
  }
  try {
    const cartItems = await API.get('cart')
    CartStore.setCart(cartItems.data.data)
  } catch (error) {
  }
}

export const useGetCartItems = () => {
  useEffect(() => {
    getCartItems()
  }, [])
}