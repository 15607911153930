import React from "react";
import DataStore from "stores/DataStore";

const LandingPageSuccess = () => {
  const { isGettingData } = DataStore;

  return (
    <div className="h-screen flex justify-center">
      {!isGettingData && (
        <div className="relative top-[300px] text-center">
          <div className="flex justify-center mb-6">
            <svg
              width="71"
              height="72"
              viewBox="0 0 71 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M35.5009 0.799948C42.4628 0.799948 49.2684 2.86439 55.057 6.73222C60.8456 10.6 65.3573 16.0975 68.0215 22.5295C70.6857 28.9615 71.3828 36.039 70.0246 42.8671C68.6664 49.6953 65.3139 55.9673 60.3911 60.8901C55.4683 65.8129 49.1962 69.1654 42.3681 70.5236C35.54 71.8818 28.4624 71.1847 22.0305 68.5205C15.5985 65.8563 10.101 61.3446 6.23319 55.556C2.36537 49.7674 0.300924 42.9618 0.300924 35.9999C0.287725 31.3737 1.18919 26.7905 2.95348 22.5139C4.71777 18.2373 7.31008 14.3516 10.5813 11.0804C13.8526 7.8091 17.7382 5.21679 22.0149 3.4525C26.2915 1.68821 30.8747 0.786749 35.5009 0.799948Z"
                fill="#3BB54A"
              />
              <path
                d="M55 26.3002L30 51.3002L16 37.4002L21.7 31.8002L30 40.0002L49.3 20.7002L55 26.3002Z"
                fill="#D4E1F4"
              />
            </svg>
          </div>
          <h1>Payment Successful!</h1>
          <h4 className="m-b-40">The transaction was successful.</h4>
        </div>
      )}
    </div>
  );
};

export default LandingPageSuccess;
