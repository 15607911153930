import { useEffect } from 'react'
import BookingStore from 'stores/BookingStore'
import BookingAPI from 'Utils/BookingAPI'
import ServicesStore from 'stores/ServicesStore';

const useGetClinicAppointment = (formData) => {
    const getClinicAppointment = async () => {
        try {
            const clinicData = await BookingAPI.post('parse_hash', formData)
            BookingStore.setClinicData(clinicData.data)
            if(ServicesStore.preselectedService) {
                BookingStore.setAppointmentType(ServicesStore.preselectedServiceType)
            }
        } catch (error) {
        } 
    }

    useEffect(() => {
        getClinicAppointment()
    }, [])

}

export default useGetClinicAppointment;