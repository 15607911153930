import axios from "axios";
import { toast } from 'react-toastify'
import DataStore from "stores/DataStore";
const { pendingRequests, pendingRequestsIncrease, pendingRequestsDecrease, setIsGettingData } = DataStore

const PPAPI = axios.create({
  withCredentials: false,
  headers: {
    'Accept': 'application/json'
  },
  baseURL: process.env.REACT_APP_PP_API_URL || '/'
})

PPAPI.interceptors.request.use(function (config) {
  pendingRequestsIncrease()
  setIsGettingData(true)
  return config;
}, function (error) {
  setIsGettingData(false)
  return Promise.reject(error);
});

PPAPI.interceptors.response.use(function (response) {
  pendingRequestsDecrease()
  if(pendingRequests === 0) setIsGettingData(false)
  return response;
}, function (error) {
  if(pendingRequests === 0) setIsGettingData(false)
  toast.error(error.response.data.message)
  return Promise.reject(error);
});

export default PPAPI;