import ButtonPrimary from 'component/Common/ButtonPrimary';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef } from 'react'
import { getOTPCode, registerLeadOb, verifyOTP } from 'services/HttpServices';
import BookingStore from 'stores/BookingStore';
import DataStore from 'stores/DataStore';
import '../../../css/Themes.scss'
import ClinicsStore from "../../../stores/ClinicsStore";
import { subdomain } from 'Utils/globalActions'
import CommonModal from 'component/Common/CommonModal';

const CheckPhoneModal = (props) => {
    const { setShowPhoneModal, setStep } = props;
    const { accountConfig } = DataStore;
    const { selectedClinic } = ClinicsStore;
    const { contactInfo, setNumberVerified, consent } = BookingStore;
    const [ otpNumber, setOtpNumber ] = useState(Array(6).fill(''));


    const chars = useRef([])

    const handleCharInput = (event, index) => {
        const value = event.target.value[0] || ''
        const updatedOtpNumber = [...otpNumber]
        updatedOtpNumber[index] = value
        setOtpNumber(updatedOtpNumber)
        if(value) {
            chars[Math.min(index+1, 5)].setSelectionRange(0,0)
            chars[Math.min(index+1, 5)].focus()
        }
    }

    const handleKeyDown = (event, index) => {
       if(event.keyCode === 8 && !event.target.value[0]) {
            chars[Math.max(0, index-1)].focus()
        }
    }

    const resendOtp = () => {
        getOTPCode({ 
            firstname: contactInfo.firstname ,
            lastname: contactInfo.lastname,
            email: contactInfo.email,
            phone_number: contactInfo.phone_number
        }, null , true )
    }

    const verifyMobileNumber = () => {
        const successVerify = () => {
            setStep(2)
            setShowPhoneModal(false)
            setNumberVerified(contactInfo.phone_number)
            if (consent) {
              registerLeadOb();
            }
        }
        if(otpNumber.join('').split('').length < 6) return;

        setOtpNumber(Array(6).fill(''))

        verifyOTP({
            phone_number: contactInfo.phone_number,
            otp_code: otpNumber.join(''),
            clinic_id: selectedClinic.id,
            subdomain
        }, successVerify)
    }

    return (
        <CommonModal>
            <div className={` ${accountConfig.theme}-theme secondary-bg w-[56px] h-[56px] border-[10px] border-[#0466c80a] rounded-[100%] flex items-center justify-center`}>
                <span>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill='none' className={`${accountConfig.theme}-theme stroke-primary`} xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.77699 9.32891C8.58899 11.0201 9.69591 12.6052 11.0977 14.0071C12.4996 15.4089 14.0847 16.5158 15.7759 17.3278C15.9214 17.3977 15.9941 17.4326 16.0861 17.4594C16.4132 17.5548 16.8149 17.4863 17.0919 17.2879C17.1698 17.2321 17.2365 17.1654 17.3699 17.0321C17.7777 16.6242 17.9817 16.4203 18.1867 16.2869C18.9601 15.7841 19.9571 15.7841 20.7305 16.2869C20.9356 16.4203 21.1395 16.6242 21.5474 17.0321L21.7747 17.2594C22.3947 17.8794 22.7047 18.1894 22.8731 18.5224C23.2081 19.1845 23.2081 19.9665 22.8731 20.6287C22.7047 20.9616 22.3947 21.2716 21.7747 21.8916L21.5908 22.0755C20.9729 22.6934 20.664 23.0024 20.2439 23.2383C19.7778 23.5002 19.0539 23.6884 18.5194 23.6868C18.0376 23.6854 17.7083 23.5919 17.0498 23.405C13.5108 22.4006 10.1714 20.5053 7.38544 17.7194C4.59946 14.9334 2.70424 11.594 1.69977 8.05501C1.51287 7.39649 1.41941 7.06723 1.41798 6.58546C1.41639 6.05086 1.60464 5.32696 1.86647 4.86087C2.10242 4.44083 2.41137 4.13188 3.02926 3.51399L3.21317 3.33008C3.83319 2.71007 4.14319 2.40006 4.47614 2.23166C5.13829 1.89674 5.92027 1.89674 6.58242 2.23165C6.91537 2.40006 7.22538 2.71006 7.84539 3.33008L8.07274 3.55743C8.48062 3.9653 8.68455 4.16924 8.81789 4.37431C9.32071 5.14769 9.32071 6.1447 8.81788 6.91807C8.68455 7.12314 8.48062 7.32708 8.07274 7.73495C7.93938 7.86832 7.8727 7.935 7.81688 8.01295C7.61853 8.28995 7.55005 8.69159 7.64539 9.01866C7.67222 9.1107 7.70715 9.18344 7.77699 9.32891Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </span>
            </div>
            <h4>Check your phone</h4>
            <span className='text-[#94A3B8]'>We sent an OTP to</span>
            <span className='font-bold text-lg'>{`+${contactInfo.phone_number}`.replace('++', '+')}</span>
            <div className='flex gap-2 mt-6 mb-6'>
                {[0,1,2,3,4,5].map(item => {
                    return <input autoFocus={item === 0} type='tel' value={otpNumber[item] || ''} key={item} className={`${accountConfig.theme}-theme primary-color w-[44px] h-[44px] border border-[#CBD5E1] rounded-lg text-xl text-center outline-1 font-bold`} ref={(el) => chars[item] = el} onChange={(event) => handleCharInput(event, item)} onKeyDown={(event) => handleKeyDown(event, item)} />
                })}
            </div>
            <div className='flex mb-6'>
                <ButtonPrimary text='Verify Mobile Number' onClick={() => verifyMobileNumber()}/>
            </div>
            <div className='mb-6'><span className='text-[#344054] text-sm'>Didn’t receive the OTP? </span><button className={`${accountConfig.theme}-theme primary-color font-semibold cursor-pointer`} onClick={() => resendOtp()}>Click to resend</button></div>
            <span className='font-semibold text-[14px] cursor-pointer' onClick={() => setShowPhoneModal(false)}>Back</span>
        </CommonModal>


    )
}

export default observer(CheckPhoneModal);
