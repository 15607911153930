import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Loader from '../Loader';

const HashRedirectPage = () => {
  const routeParams = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    sessionStorage.setItem('booking-hash', routeParams.hash)
    sessionStorage.setItem('session-hash', routeParams.hash)
    navigate('/online-booking')
  }, [])

  return (
    <><Loader/></>
  );
}
export default observer(HashRedirectPage)
