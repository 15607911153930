import { useEffect } from 'react'
import API from "Utils/API";
import ClinicsStore from 'stores/ClinicsStore'
import PromotionsStore from 'stores/PromotionsStore'

const useGetPromotions = () => {
  const getPromotions = async () => {
    try {
      const response = await API.get('promotions')
      PromotionsStore.setPromotions(response.data.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (ClinicsStore.selectedClinic) {
      getPromotions()
    }
  }, [ClinicsStore.selectedClinic])

}

export default useGetPromotions;