import React from 'react'
import '../../css/Themes.scss'
import DataStore from 'stores/DataStore';
import GlobeIcon from 'img/GlobeIcon';
import MailIcon from 'img/MailIcon';
import PhoneIcon from 'img/PhoneIcon';


const ContactInfo = (props) => {
    const { icon, children } = props;
    const { accountConfig } = DataStore
    return (
        <div className={`${accountConfig.theme}-theme secondary-button flex p-3 rounded-[30px] items-center text-base justify-center font-semibold`}>
            <div className="mx-2">
                {icon === 'globeIcon' && <GlobeIcon />}
                {icon === 'mailIcon' &&  <MailIcon />}
                {icon === 'phoneIcon' && <PhoneIcon />}
            </div>
            <div className="text-base xl:text-lg">
                {children}
            </div>
        </div>
    )
}

export default ContactInfo;
