import { makeAutoObservable, observable, computed } from "mobx"

class DataStore {
  isGettingData = false
  pendingRequests = 0
  accountConfig = {}
  accountInfo = {}
  patientInfo = {}

  constructor () {
    makeAutoObservable(this, {
      isGettingData: observable,
      accountConfig: observable,
      accountInfo: observable,
      patientInfo: observable,
      isLoggedIn: computed,
      fullName: computed,
      isServicesDisabled: computed,
      isCartAvailable: computed,
    })
  }

  get isServicesDisabled () {
    return this.accountConfig?.is_services_active === false
  }

  get isCartAvailable () {
    return this.accountConfig.is_promotion_active || this.accountConfig.is_membership_active || this.accountConfig.is_gift_card_active
  }

  initialize = (data) => {
    this.accountConfig = data.config
    this.accountInfo = data.info
  }

  setIsGettingData = (value) => {
    this.isGettingData = value
  }

  setPatientInfo = (value) => {
    this.patientInfo = value
  }

  get isLoggedIn () {
    return this.patientInfo.hasOwnProperty('email')
  }

  get fullName () {
    return `${this.patientInfo.firstname} ${this.patientInfo.lastname}`
  }

  pendingRequestsIncrease = () => {
    this.pendingRequests++
  }

  pendingRequestsDecrease = () => {
    this.pendingRequests--
  }

  reset = () => {
    this.accountConfig = {}
    this.accountInfo = {}
  }
}

export default new DataStore();
