import ButtonPrimary from 'component/Common/ButtonPrimary';
import CommonModal from 'component/Common/CommonModal';
import React from 'react'
import { formatDescriptionText } from 'Utils/globalActions';

const ReadMoreModal = (props) => {
    const {readMore, setShowReadMoreModal} = props;

    return (
        <CommonModal>
                <h5 className='mb-6'>{readMore.title}</h5>
                <div className='mb-6'>{formatDescriptionText(readMore.description)}</div>
                <div className='flex w-full justify-end'>
                    <ButtonPrimary text='Done' onClick={() => setShowReadMoreModal(false)} />
                </div>
        </CommonModal>
    )
}

export default ReadMoreModal;