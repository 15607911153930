import React from 'react'
import DataStore from 'stores/DataStore';
import '../../css/Themes.scss'

const ButtonPrimary = (props) => {
    const { text, onClick, disabled } = props;
    const { accountConfig } = DataStore;

    return (
        <button className={`${accountConfig.theme}-theme primary-button py-2 px-6 rounded-[30px] font-semibold whitespace-nowrap text-sm select-none ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={onClick} disabled={disabled}>
               {text}
        </button>
    )
}

export default ButtonPrimary;