import { makeAutoObservable } from "mobx"

class StaffStore {
    staffData = []
    preselectedProvider = null

    constructor() {
        makeAutoObservable(this)
    }

    get preselectedProviderServicesIds() {
        return this.preselectedProvider.available_services.map(service => service.id)
    }

    setStaffData = (value) => {
        this.staffData = value
    }

    setPreselectedProvider = (value) => {
        this.preselectedProvider = value
    }
}

export default new StaffStore();
