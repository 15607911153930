import React, { useEffect } from "react";
import Leftside from "../Leftside";
import RightContainer from "../RightContainer";
import GiftCardBox from "../GiftCards/GiftCardBox";
import DataStore from "stores/DataStore";
import { observer } from "mobx-react-lite";
import GiftCardStore from "stores/GiftCardStore";
import useGetEGiftCards from "../../hooks/useGetEGiftCards";
import '../../css/Themes.scss'
import ButtonPrimary from "component/Common/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { formatCurrencyAmount } from "Utils/globalActions";
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const GiftCardsPage = () => {

  const { giftCards } = GiftCardStore;
  const { accountConfig, accountInfo } = DataStore;

  useGetEGiftCards()

  const navigate = useNavigate()
  const currencyCode = accountInfo.currency_code || 'USD'

  const handleClick = (giftCard) => {
    navigate(`/online-booking/giftcards/card/${giftCard.id}`)
  }

  useEffect(() => {
    GAEvent(GAEventTypes.EGIFT_PAGE_VIEW);
  }, [])
  
  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div
            className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside/>
            <RightContainer title='eGift Cards'>
              <div className="grid grid-cols-1 grid-rows-1 gap-4 sm:grid-cols-2">
                {Array.from(giftCards.values()).map(giftCard => {
                  return (
                    <div className="p-4 border-[1px] border-[#E2E8F0] rounded-lg" key={giftCard.id}>
                      <GiftCardBox key={giftCard.id} giftCard={giftCard}/>
                      <div className='flex justify-between items-center'>
                        <div className='text-[#475569]'>
                          <span className='font-bold'>Price:</span> {formatCurrencyAmount(currencyCode, giftCard.price)}
                        </div>
                        <ButtonPrimary text='Buy Now' onClick={() => handleClick(giftCard)} />
                      </div>
                    </div>
                  )
                })}
              </div>
            </RightContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(GiftCardsPage);