import ButtonPrimary from 'component/Common/ButtonPrimary';
import CommonModal from 'component/Common/CommonModal';
import React from 'react'
import DataStore from 'stores/DataStore';
import { formatCurrencyAmount } from 'Utils/globalActions';

const DepositModal = (props) => {
    const { deposit, setShowDeposit } = props;
    const { accountInfo } = DataStore;

    const currencyCode = accountInfo.currency_code || 'USD'

    return (
        <CommonModal>
            <h5 className='mb-6'>The service has a deposit!</h5>
            <h6 className='mb-6 text-[#525252]'>This service requires a deposit of {formatCurrencyAmount(currencyCode, deposit)} to book. Please click Continue to proceed.</h6>
            <div className='flex w-full justify-end'>
                <ButtonPrimary text='Continue' onClick={() => setShowDeposit(false)} /> 
            </div>
        </CommonModal>
    )
}

export default DepositModal;