import React, { useState } from 'react'
import DataStore from 'stores/DataStore';
import { addItemToCart } from 'services/HttpServices';
import MembershipModal from './MembershipModal';
import { ItemTypes, MembershipTypes } from "Utils/constants"
import MembershipAgreementModal from 'component/Booking/StepsContent/MembershipAgreementModal';
import ButtonPrimary from 'component/Common/ButtonPrimary';
import { useNavigate } from 'react-router-dom';
import { formatCurrencyAmount } from 'Utils/globalActions';

const MembershipBox = (props) => {
  const { membership } = props;
  const { accountInfo } = DataStore
  const showMonthly = membership.membership_payment_options === MembershipTypes.Monthly
  const showYearly = membership.membership_payment_options === MembershipTypes.Yearly
  const showBoth = membership.membership_payment_options === MembershipTypes.Both
  const currencyCode = accountInfo.currency_code || 'USD'
  const { accountConfig } = DataStore
  const [showSelectFrequencyModal, setShowSelectFrequencyModal] = useState(false)
  const [selectedFrequency, setSelectedFrequency] = useState(membership.membership_payment_options)
  const [showAgreementModal, setShowAgreementModal] = useState(false)
  const navigate = useNavigate()

  const handleAddToCart = (couponCode) => {
    let formData = {
      item_id: membership.id,
      item_type: ItemTypes.Membership,
      membership_props: {
        frequency: selectedFrequency,
      }
    }
    if(couponCode) {
      formData.membership_props.coupon_code = couponCode
    }
    addItemToCart(formData)
  }

  const handleSelectFrequency = () => {
    setShowSelectFrequencyModal(true)
  }

  const handleShowBenefits = (id) => {
      navigate(`/online-booking/memberships/membership-details/${id}`)
  }

  return (
    <div className='flex flex-col border-[2px] pb-4 border-[#E2E8F0] rounded-[16px] text-center'>
      <div className={`${accountConfig.theme}-theme secondary-bg w-full py-[16px] rounded-t-[16px]`}>
        <h4 className='text-xl text-[#475569] font-bold inline-block'>{membership?.tier_name}</h4>
      </div>
      <div className='mt-2 mb-4'>
        {showMonthly &&  <h5 className="text-xl text-[#64748B]"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, membership?.price_per_month)}</span>/Month</h5>}
        {showYearly &&  <h5 className="text-xl text-[#64748B]"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, membership?.price_per_year)}</span>/Year</h5>}
        {showBoth &&  <h5 className="text-xl text-[#64748B]"><span className='text-3xl'>{formatCurrencyAmount(currencyCode, membership?.price_per_month)}</span>/Month <span className='font-normal'>or</span> <span className='text-3xl'>{formatCurrencyAmount(currencyCode, membership?.price_per_year)}</span>/Year</h5>}
        <h5 className="text-xl text-[#94A3D8]"><span className='text-2xl'>{formatCurrencyAmount(currencyCode, membership?.one_time_setup_fee)}</span> Setup Fee</h5>
      </div>
      <div className='mt-auto'>
        {Boolean(membership?.membership_description_for_booking_portal || membership?.show_discount_on_portal) && <span className={`${accountConfig.theme}-theme primary-color font-semibold block cursor-pointer select-none hover:underline mb-3`} onClick={() => handleShowBenefits(membership?.id)}>View Details</span>}
        <div className='mt-auto'>
          {selectedFrequency !== MembershipTypes.Both && <ButtonPrimary text='Join Now' onClick={() => setShowAgreementModal(true)}/>}
          {selectedFrequency === MembershipTypes.Both && <ButtonPrimary text='Select frequency' onClick={() => handleSelectFrequency()}/>}
        </div>
      </div>

      {showSelectFrequencyModal && <MembershipModal
        showSelectFrequencyModal={showSelectFrequencyModal}
        setShowSelectFrequencyModal={setShowSelectFrequencyModal}
        selectedFrequency={selectedFrequency}
        setSelectedFrequency={setSelectedFrequency}
        membership={membership}
        setShowAgreementModal={setShowAgreementModal}
      />}
      {showAgreementModal && <MembershipAgreementModal setSelectedFrequency={setSelectedFrequency} membership={membership} handleAddToCart={handleAddToCart} setShowAgreementModal={setShowAgreementModal} agreement={membership.agreement.agreement_text} selectedFrequency={selectedFrequency} />}
    </div>
  )
}

export default MembershipBox;