import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import useGetStaff from "hooks/useGetStaff";
import StaffStore from "stores/StaffStore";
import Leftside from "../Leftside";
import RightContainer from "../RightContainer";
import StaffBox from "../Staff/StaffBox";
import DataStore from "stores/DataStore";
import '../../css/Themes.scss';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const StaffPage = () => {
    const { staffData } = StaffStore
    const { accountConfig } = DataStore;

    useGetStaff()

    useEffect(() => {
        GAEvent(GAEventTypes.STAFF_PAGE_VIEW);
    }, [])
    
    return (
        <>
        <div className={`${accountConfig.theme}-theme secondary-bg`}>
            <div className="lg:px-4 py-4 min-h-screen max-h-full" >
                <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
                    <Leftside />
                    <RightContainer title='Our Team'>
                        {staffData.map(staff => <StaffBox key={staff.id} staff={staff}  />)}
                    </RightContainer>
                </div>
            </div>
        </div>
        </>


    );
}

export default observer(StaffPage);