import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import DataStore from 'stores/DataStore';

const Authorization = (props) => {
    const { condition, children } = props
    const { accountConfig } = DataStore
    const navigate = useNavigate()
    
    const validations = {
        isStaffActive: accountConfig.is_staff_active,
        isMembershipActive: accountConfig.is_membership_active,
        isPromotionActive: accountConfig.is_promotion_active,
        isGiftCardActive: accountConfig.is_gift_card_active,
    }

    if(Object.keys(accountConfig).length === 0) {
        return null;
    }

    if(validations[condition] === true) {
        return children
    }

    return navigate('/online-booking')
}

export default observer(Authorization);
