import React, { useState } from 'react'
import ButtonPrimary from '../Common/ButtonPrimary';
import DataStore from 'stores/DataStore';
import { addItemToCart } from 'services/HttpServices';
import { ItemTypes } from "Utils/constants"
import { formatCurrencyAmount, formatDescriptionText } from 'Utils/globalActions';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const PromotionCard = (props) => {
  const { promotion, image, id } = props;
  const { accountConfig, accountInfo } = DataStore;
  const [showDetails, setShowDetails] = useState(false)

  const handleAddToCart = () => {
    addItemToCart({
      item_id: id,
      item_type: ItemTypes.Promotion
    }, () => {
      GAEvent(GAEventTypes.PROMOTION_ADDED);
    })
  }
  const currencyCode = accountInfo.currency_code || 'USD'

  return (
    <>
      <div className='promotions_card flex rounded-2xl min-h-[230px] pr-8 xl:gap-4'>
        {(!showDetails && image) &&
        <img src={image} className='w-1/3 rounded-br-[100%] rounded-l-xl object-cover' alt=''/>}
        <div className={`flex flex-col py-6 ${(showDetails || !image) ? 'pl-[56px]' : ''}`}>
          <h5
            className="promotion_title text-[#64748B] text-xl font-bold mt-auto">{promotion.promotion_name_for_booking_portal}</h5>
          {!showDetails && <div className='text-[#475569]'>
            {(promotion.package_bogo_price === promotion.package_price_for_members)
              ? <><span className='font-bold'>Price: </span>{formatCurrencyAmount(currencyCode, promotion.package_bogo_price)}</>
              : <>
                <span className='font-bold'>Member Price: {formatCurrencyAmount(currencyCode, promotion.package_price_for_members)}</span><br/>
                Regular Price: {formatCurrencyAmount(currencyCode, promotion.package_bogo_price)}
              </>
            }
          </div>}
          {showDetails && <h6
            className="promotion_description text-[#64748B] text-sm">{formatDescriptionText(promotion.promotion_description_for_booking_portal)}</h6>}
          <div className='mt-auto flex gap-2'>
            <ButtonPrimary onClick={handleAddToCart} text='Buy Now'/>
            <button
              className={`${accountConfig.theme}-theme primary-color bg-white py-2 px-6 rounded-[30px] font-semibold whitespace-nowrap cursor-pointer text-sm select-none`}
              onClick={() => setShowDetails(!showDetails)}>{`${showDetails ? 'Close' : 'Details'}`}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PromotionCard;