import CartItem from "component/Cart/CartItem";
import Leftside from "component/Leftside";
import RightContainer from "component/RightContainer";
import { useGetCartItems, getCartItems } from "hooks/useGetCartItems";
import { observer } from "mobx-react-lite";
import CartStore from "stores/CartStore";
import DataStore from "stores/DataStore";
import { generateInvoice } from "services/HttpServices";
import "../../css/Themes.scss";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "component/Common/ButtonPrimary";
import { formatCurrencyAmount } from "Utils/globalActions";

const CartPage = (props) => {
  const { cartItems, totalPrice, totalTax, setInvoiceData, isGuestCheckout } = CartStore;
  const { accountConfig, accountInfo, isLoggedIn } = DataStore;
  const navigate = useNavigate();
  const currencyCode = accountInfo.currency_code || "USD";

  const handleProceedToCheckout = async () => {
    if (!isLoggedIn && !isGuestCheckout) {
      return navigate("/online-booking/redirect/login-cart");
    }

    try {
      const response = await generateInvoice();
      setInvoiceData(response.data.data);
      navigate("/online-booking/cart/payment");
    } catch (error) {
      getCartItems();
    }
  };

  useGetCartItems();

  return (
    <>
      <div className={`${accountConfig.theme}-theme secondary-bg`}>
        <div className="lg:px-4 py-4 min-h-screen max-h-full">
          <div className="flex gap-[8px] justify-between xl:justify-center xl:gap-4 max-lg:flex-col-reverse lg:flex-row ">
            <Leftside />
            <RightContainer title="My Cart">
              {cartItems.size === 0 ? (
                <p className="text-[#64748B]">Your Cart is Empty!</p>
              ) : (
                ""
              )}
              <div className="flex flex-col gap-4 mb-4">
                {Array.from(cartItems.values()).map((cartItem) => (
                  <CartItem key={cartItem.id} cartItem={cartItem} />
                ))}
              </div>
              <div className="w-full flex justify-between gap-5 items-end">
                {totalTax > 0 ? (
                  <div className="flex flex-col ml-5">
                    <span className="text-[#1E293B]">
                      Subtotal:{" "}
                      <span>
                        {formatCurrencyAmount(
                          currencyCode,
                          totalPrice.toFixed(2)
                        )}
                      </span>
                    </span>
                    <span className="text-[#1E293B]">
                      Tax:{" "}
                      <span>
                        {formatCurrencyAmount(
                          currencyCode,
                          totalTax.toFixed(2)
                        )}
                      </span>
                    </span>
                    <span
                      className={`${accountConfig.theme}-theme primary-color font-bold`}
                    >
                      Total Cart:{" "}
                      <span>
                        {formatCurrencyAmount(
                          currencyCode,
                          (totalPrice + totalTax).toFixed(2)
                        )}
                      </span>
                    </span>
                  </div>
                ) : (
                  <span
                    className={`${accountConfig.theme}-theme primary-color font-bold`}
                  >
                    Total Cart:{" "}
                    <span>
                      {formatCurrencyAmount(
                        currencyCode,
                        totalPrice.toFixed(2)
                      )}
                    </span>
                  </span>
                )}
                <ButtonPrimary
                  disabled={cartItems.size === 0}
                  text={
                    cartItems.size === 0 || totalPrice > 0
                      ? "Proceed to Checkout"
                      : "Proceed"
                  }
                  onClick={handleProceedToCheckout}
                />
              </div>
            </RightContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CartPage);
