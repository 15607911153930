import React from 'react';
import Eclipse from '../img/Eclipse.gif';
const Loader = () => {

    return (
      <div className='flex justify-center items-center fixed w-full h-full bg-[rgba(255,255,255,0.8)] text-sm z-[9999]'>
        <div className="loader-outer m-t-10-percent">
          <img src={Eclipse} alt='' className='h-[60px] m-auto' />
          <div id="modal-confirm-text" className="popup-subtitle" >Processing. Please Wait...</div>
        </div>
      </div>
    )
}

export default Loader;