import React from 'react'
import Step from './Step';
import { useScrollToTop } from 'hooks/useSrollToTop';

const Steps = (props) => {
    const { step } = props;
    useScrollToTop();

    return (
        <div className='flex overflow-x-auto gap-[8px] mb-8 lg:gap-4 2xl:gap-8'>
            <Step number={1} step={step} text={'Enter Information'} />
            <Step number={2} step={step} text={'Select Service(s)'} />
            <Step number={3} step={step} text={'Select Provider'} />
            <Step number={4} step={step} text={'Select Date & Time'} />
        </div>
    )
}

export default Steps;