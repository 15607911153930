import { makeAutoObservable } from "mobx"

class ServicesStore {
    servicesData = []
    selectedServiceCategory = null
    preselectedService = null
    deposit = null
    showDeposit = false
    loadedServices = false

    constructor() {
        makeAutoObservable(this)
    }

    get servicesNames() {
        return Object.values(this.servicesData).map(service => {
            return { name: service.name, id: service.id, order_by: service.order_by }
        }).sort((a, b) => {
            return a.order_by - b.order_by;
        })
    }

    get preselectedServiceType() {
        return this.preselectedService.service_type
    }

    get preselectedServiceId() {
        return this.preselectedService.id
    }

    get availableServices() {
        return Object.values(this.servicesData).find(service => service.name === this.selectedServiceCategory?.name)
    }

    setServicesData = (value) => {
        this.servicesData = value
        this.loadedServices = true
    }

    setSelectedServiceCategory = (value) => {
        this.selectedServiceCategory = value
    }

    setPreselectedService = (value) => {
        this.preselectedService = value
    }

    setDeposit = (value) => {
        this.deposit = value
    }

    setShowDeposit = (value) => {
        this.showDeposit = value
    }
}

export default new ServicesStore();