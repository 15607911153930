import { makeAutoObservable } from "mobx"

class MembershipsStore {
  promotions = new Map()

  constructor () {
    makeAutoObservable(this)
  }

  setPromotions = (promotions) => {
    this.promotions = new Map(promotions.map((promotion) => [promotion.id, promotion]));
  }
}

export default new MembershipsStore();