import CommonModal from 'component/Common/CommonModal';
import React from 'react'

const CancellationPolicyModal = (props) => {
    const { setShowCancellationPolicyModal, text, handleAccept } = props;


    return (
        <CommonModal>
            <h5 className='mb-6'>Cancellation Policy</h5>
            <div dangerouslySetInnerHTML={{__html: text}} className='overflow-auto mb-4 w-full' />
            <div className='flex w-full justify-end'>
                <button className='py-1 px-3 bg-[green] text-[white] rounded-md mr-2' onClick={() => handleAccept()}>Accept</button>    
                <button className='py-1 px-3 bg-[#d52d2d] text-[white] rounded-md' onClick={() => setShowCancellationPolicyModal(false)}>Decline</button>   
            </div>
        </CommonModal>
    )
}

export default CancellationPolicyModal;
