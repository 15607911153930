import React from 'react'

 const CommonModal = ({ children }) => {
  return (
    <div className='flex justify-center items-center z-[999] fixed top-0 left-0 bg-[rgba(0,0,0,0.25)] w-full h-screen px-4'>
        <div className={`flex flex-col items-center max-w-$  bg-white rounded-2xl p-6 max-w-[500px] max-h-[90%] overflow-auto`}>
          {children}
        </div>
    </div>
  )
}

export default CommonModal;