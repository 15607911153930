import { useEffect } from 'react'
import API from "Utils/API";
import GiftCardStore from 'stores/GiftCardStore'

const useGetEGiftCards = () => {
  const getEgiftCards = async () => {
    try {
      const giftCardData = await API.get('gift-cards')
      GiftCardStore.setGiftCards(giftCardData.data.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    getEgiftCards()
  }, [])

}

export default useGetEGiftCards;