import { useEffect } from 'react'
import API from "Utils/API";
import MembershipsStore from 'stores/MembershipsStore'

const useGetMemberships = () => {
  const getMemberships = async () => {
    try {
      const response = await API.get('memberships')
      MembershipsStore.setMemberships(response.data.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    getMemberships()
  }, [])

}

export default useGetMemberships;