import { useEffect } from 'react'
import API from "Utils/API";
import ClinicsStore from 'stores/ClinicsStore'
import StaffStore from 'stores/StaffStore'

const useGetStaff = () => {
  const getStaff = async () => {
    try {
      const staffData = await API.get('staff')
      StaffStore.setStaffData(staffData.data.data)
    } catch (error) {
    }
  }

  useEffect(() => {
    if (ClinicsStore.selectedClinic) {
      getStaff()
    }
  }, [ClinicsStore.selectedClinic])

}

export default useGetStaff;