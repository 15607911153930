import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'component/Loader';

const RedirectPage = () => {
  const routeParams = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_PP_URL || '/'
    switch (routeParams?.where) {
      case 'dashboard':
        window.location.href = `${baseUrl}dashboard`
        break;
      case 'login':
        window.location.href = `${baseUrl}login?redirect=ob`
        break;
      case 'login-cart':
        window.location.href = `${baseUrl}login?redirect=ob-cart`
        break;
      default:
        navigate('/online-booking/')
        break;
    }
  }, [])

  return (<Loader/>)
}

export default RedirectPage;