import { makeAutoObservable } from "mobx"

class MembershipsStore {
  memberships = new Map()
  signature = null

  constructor () {
    makeAutoObservable(this)
  }

  setMemberships = (memberships) => {
    this.memberships = new Map(memberships.map((membership) => [membership.id, membership]));
  }

  setSignature = (value) => {
    this.signature = value
  }
}

export default new MembershipsStore();