import React from 'react'
import '../../css/Themes.scss'
import DataStore from 'stores/DataStore';

const Step = (props) => {
    const { number, text, step } = props;
    const { accountConfig } = DataStore;

    return (
        <div className={`w-full h-[100px] p-[16px] flex flex-col justify-around items-center whitespace-nowrap bg-[#F1F5F9] border-[1px] border-[#CBD5E1] rounded-md  ${step === number ? `${accountConfig.theme}-theme card-active` : ''}`}>
            <span className={`flex w-6 h-6 justify-center items-center bg-[#CBD5E1] p-2 rounded-full text-white ${step >= number ? `${accountConfig.theme}-theme primary-button` : ''}`}>{number}</span>
            <span className='text-[#525252]'>{text}</span>
        </div>
    )
}

export default Step;