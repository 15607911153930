import { useEffect } from 'react'
import API from "Utils/API";
import { getCartItems } from './useGetCartItems';
import CartStore from 'stores/CartStore';
import { useNavigate } from 'react-router-dom';
import { GAEvent, GAEventTypes } from 'Utils/GoogleAnalytics';

const useGetInvoiceData = () => {
    const { invoiceData, setInvoiceData } = CartStore;
    const navigate = useNavigate()
    let checkInvoiceInterval = null
    let checkInvoiceTimeout = null

    const getInvoiceData = async () => {
        try {
            const response = await API.get('invoice')

            if (response.data.data.invoice_status === 'paid') {
                GAEvent(GAEventTypes.CHECKOUT);
                clearInterval(checkInvoiceInterval)
                clearTimeout(checkInvoiceTimeout)
                getCartItems()
                navigate('/online-booking/cart/completed-payment')
            }
            if (response.data.data.invoice_status === 'draft') {
                clearInterval(checkInvoiceInterval)
                clearTimeout(checkInvoiceTimeout)
                navigate('/online-booking/cart')
            }
            setInvoiceData(response.data.data)
        } catch (error) {
        }
    }

  useEffect(() => {
    getInvoiceData()

    checkInvoiceInterval = setInterval(() => {
        getInvoiceData()
    }, 3000)

    checkInvoiceTimeout = setTimeout(() => {
        if (invoiceData?.invoice_status !== 'paid') {
            navigate('/online-booking/cart')
        }
    }, 21000)
    return () => {clearInterval(checkInvoiceInterval); clearTimeout(checkInvoiceTimeout)}
  }, [])

}

export default useGetInvoiceData;